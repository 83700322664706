import React from 'react';
import PropTypes from 'prop-types';
import Row from 'sash-foundation/dist/row';
import Column from 'sash-foundation/dist/column';
import List from 'sash-foundation/dist/list';
import BaseButton from '../baseButton/index';
import BaseImage from '../baseImage/index';

import './index.scss';

const CTARibbon = ({ data, className }) => {
	if (!data) return null;

	const setBgColor = (color) => {
		switch (color) {
			case 'black':
				return 'spal-ctaRibbon--black';
			case 'white':
				return 'spal-ctaRibbon--white';
			case 'yellow':
				return 'spal-ctaRibbon--yellow';
			case 'image':
				return 'spal-ctaRibbon--image';
			default:
				return '';
		}
	};

	const renderHeading = () => (
		<p
			className='spal-ctaRibbon-Heading'
			dangerouslySetInnerHTML={{ __html: data.heading }}
		/>
	);

	const renderSubheading = () => (
		<p
			className='spal-ctaRibbon-Subheading'
			dangerouslySetInnerHTML={{ __html: data.subheading }}
		/>
	);

	const renderDisclaimer = () => (
		<p
			className='spal-ctaRibbon-Disclaimer'
			dangerouslySetInnerHTML={{ __html: data.disclaimer }}
		/>
	);

	const renderRightText = () => (
		<p
			className='spal-ctaRibbon-RightText'
			dangerouslySetInnerHTML={{ __html: data.right_text }}
		/>
	);

	const renderImage = () => (
		<BaseImage
			data={{ image: { url: data.icon.url } }}
			className='spal-ctaRibbon-Image'
			lazy={true}
		/>
	);

	const renderBtns = () => (
		<List
			bullets='none'
			layout='fluid'
			className='spal-ctaRibbon-BtnList'
		>
			{data.cta.map((link, index) => (
				<li key={index}>
					<BaseButton
						data={link}
						className='spal-ctaRibbon-Btn'
					/>
				</li>
			))}
		</List>
	);

	return (
		<div className={`spal-ctaRibbon ${setBgColor('black')} ${className || ''}`}>
			{data.ribbon_style === 'promo' && (
				<div className='spal-ctaRibbon--promo'>
					{data.heading && renderHeading()}
					{data.subheading && renderSubheading()}
					{data.right_text && renderRightText()}
				</div>
			)}

			{data.ribbon_style === 'right-text' && (
				<div className='spal-ctaRibbon--rightText'>
					{data.heading && renderHeading()}
					{data.disclaimer && renderDisclaimer()}
					{data.right_text && renderRightText()}
				</div>
			)}

			{data.ribbon_style === 'cta' && (
				<div className='spal-ctaRibbon--default'>
					<Row
						align='center'
						className='spal-ctaRibbon-Row'
					>
						<Column
							xs={12}
							lg='fluid'
						>
							{data.heading && renderHeading()}
							{data.subheading && renderSubheading()}
						</Column>

						<Column
							xs={12}
							lg='auto'
						>
							{data.cta?.[0] && renderBtns()}
						</Column>
					</Row>
				</div>
			)}
		</div>
	);
};

CTARibbon.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
};

export default CTARibbon;
