import React from 'react';
import PropTypes from 'prop-types';
import sessionstorage from 'sessionstorage';
import { graphql, StaticQuery } from 'gatsby';
import { Container, List, Row, Column } from 'sash-foundation';

import "./index.scss";

const Nav = () => {
	const role = sessionstorage.getItem('userRole');

	return (
		<StaticQuery
			query={query}
			render={data => (
				<nav className='main-nav'>
					<Container>
						<List
							layout='inline'
							bullets='none'
						>
							{data.contentstackNavigation.main_nav.map((nav, index) => {
								if (role !== 'ADMIN' && nav.title === 'Reports') { return null; }

								return (
									<li key={index}>
										{
											(nav.main_link && nav.main_link.length > 0) ? (
												<a
													href={nav.main_link[0].link.href}
													data-gtm-linktype={nav.main_link[0].gtm_link_type}
													data-gtm-linktext={nav.main_link[0].gtm_link_text}
													target={nav.main_link[0].target}
													rel={nav.title !== 'Lead Times' && nav.main_link[0].target === '_blank' ? 'noopener noreferrer' : ''}
													title={nav.main_link[0].ada_title}
												>
													{nav.main_link[0].link.title}
												</a>
											) : (
												<button>{nav.title}</button>
											)
										}

										{
											nav.sub_nav &&
											nav.sub_nav.length > 0 &&
											<div className='subnav-links'>
												<Row>
													{nav.sub_nav.map((subnav, index) => (
														<Column xs='3' key={index}>
															<List
																bullets='none'
															>
																{subnav.title && subnav.title !== '' ? <li className='subnav-title'>{subnav.title}</li> : ''}
																{subnav.sub_link.map((link, index) => (
																	<li key={index}>
																		<a
																			href={link.link.href}
																			data-gtm-linktype={link.gtm_link_type}
																			data-gtm-linktext={link.gtm_link_text}
																			target={link.target}
																			rel={link.target === '_blank' ? 'noopener noreferrer' : ''}
																			title={link.ada_title}
																		>
																			{link.link.title}
																		</a>
																	</li>
																))}
															</List>
														</Column>
													))}
												</Row>
											</div>
										}
									</li>
								);
							})}
						</List>
					</Container>
				</nav>
			)}
		/>
	);
};

Nav.propTypes = {
	data: PropTypes.object
};

export default Nav;

export const query = graphql`
{
	contentstackNavigation {
		main_nav {
		  	title
			main_link {
		  		...link
			}

			sub_nav {
		  		title
		  		sub_link {
					...link
		  		}
			}
		}
	}
}
`;
