import React from "react";
import PropTypes from "prop-types";
import { Button } from "sash-foundation";

const BUTTON = (props) => {
	const { data, children, ...other } = props;
	const {
		link          = {},
		gtm_link_type = "",
		gtm_link_text = "",
		...otherAttributes
	} = data;

	return (
		<Button
			gtmLinkType = { gtm_link_type }
			gtmLinkText = { gtm_link_text }
			href        = { link.href }
			{...otherAttributes}
			{...other}
		>
			{children || link.title}
		</Button>
	);
};

BUTTON.propTypes = {
	data: PropTypes.object,
	children: PropTypes.any,
};

export default BUTTON;
