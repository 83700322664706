import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MdKeyboardArrowDown, MdSystemUpdate, MdVisibility } from 'react-icons/md';
import { Container, Row, Column, List } from 'sash-foundation';
import { SEO, Hero, BaseLink, BaseImage } from '../../components';
import CTARibbon from '../../components/common/cta-ribbon';
import Layout from '../../components/layout/layout';
import sessionStorage from 'sessionstorage';
import './index.scss';

const ResourceLanding = (props) => {
	const pageData = props.data.contentstackResourceLanding;
	const [activeResource, setActiveResource] = useState('');
	const [userCompany, setUserCompany] = useState(sessionStorage.getItem('userCompany') || '');
	const [userRole, setUserRole] = useState(sessionStorage.getItem('userRole') || '');
	const [banner, setBanner] = useState([]);
	const [update, setUpdate] = useState(false);

	const [selectedCompany, setSelectedCompany] = useState(sessionStorage.getItem('selectedCompany') || '');

	const onResourceBtnClick = (name) => {
		if (activeResource === name) {
			setActiveResource('');
		} else {
			setActiveResource(name);
		}
	};

	useEffect(() => {
		if (update) {
			setUpdate(false);
		}
	}, [update]);

	useEffect(() => {
		if (pageData) {
			pageData.ribbons.forEach((data) => {
				if (data.visibility_role.includes(selectedCompany)) {
					banner.push(data);
				} else if (data.visibility_role.includes(userCompany) && selectedCompany === '') {
					banner.push(data);
				}
			});
		}
		setUpdate(true);
	}, [pageData, selectedCompany]);

	return (
		<Layout TopCTA={true}>
			{/* <SEO {...pageData.seo} /> */}

			{/* {pageData.hero &&
				<Hero data={pageData.hero} />
			} */}

			{/* {pageData.resources?.[0] &&
				<div className='coop-resourcePopular sf-py--lg sf-py--xxl'>
					<Container width={1530}>
						{pageData.heading &&
							<h2 className='sf-text--center sf-mb--lg'>{pageData.heading}</h2>
						}
						<List bullets='none' className='coop-resourcePopular-List'>
							{pageData.resources.map((cta, index) => (
								<li key={index}>
									{cta.cta?.[0] && <BaseLink data={cta.cta[0]} className='coop-resourcePopular-Btn' />}
								</li>
							))}
						</List>
					</Container>
				</div>
			} */}

			{/* {pageData.cta_ribbon?.[0] && pageData.cta_ribbon?.[0].heading !== "" &&
				<Container width={1530}>
					<CTARibbon data={{ ...pageData.cta_ribbon[0], ribbon_style: 'promo' }} />
				</Container>
			} */}

			{banner.length > 0 && (
				<Container width={1920}>
					{banner.map((item, index) => (
						<div key={index}>
							{item.cta_ribbon?.[0] && item.cta_ribbon?.[0].heading !== '' && (
								<CTARibbon
									data={{ ...item.cta_ribbon[0] }}
									className='coop-home-ctaRibbon'
								/>
							)}
						</div>
					))}
				</Container>
			)}

			{pageData.all_resources?.group?.[0] && (
				<div className='coop-resourceAll sf-py--lg sf-py--xxl'>
					<Container width={1530}>
						{pageData.all_resources.heading && <h2 className='sf-text--center sf-mb--xl centerText'>{pageData.all_resources.heading}</h2>}
						{pageData.all_resources.group?.[0] && (
							<List
								bullets='none'
								className='coop-resourceAll-List'
							>
								{pageData.all_resources.group.map((item, index) => (
									<React.Fragment key={index}>
										{item.pella_user_only === false && (
											<li className={item.heading === activeResource ? 'coop-resourceAll-ListItem--active' : null}>
												<button
													className={`coop-resourceAll-Btn ${item.heading === activeResource ? 'coop-resourceAll-Btn--active' : item.heading !== activeResource && activeResource !== '' ? 'coop-resourceAll-Btn--inactive' : ''}`}
													onClick={() => onResourceBtnClick(item.heading)}
												>
													{item.icon?.url && <BaseImage data={{ image: { url: item.icon.url }, image_alt: '' }} />}
													<span>
														{item.heading}
														<MdKeyboardArrowDown />
													</span>
												</button>
											</li>
										)}

										{item.pella_user_only && selectedCompany === 'Pella' && (
											<li className={item.heading === activeResource ? 'coop-resourceAll-ListItem--active' : null}>
												<button
													className={`coop-resourceAll-Btn ${item.heading === activeResource ? 'coop-resourceAll-Btn--active' : item.heading !== activeResource && activeResource !== '' ? 'coop-resourceAll-Btn--inactive' : ''}`}
													onClick={() => onResourceBtnClick(item.heading)}
												>
													{item.icon?.url && <BaseImage data={{ image: { url: item.icon.url }, image_alt: '' }} />}
													<span>
														{item.heading}
														<MdKeyboardArrowDown />
													</span>
												</button>
											</li>
										)}

										{item.heading === activeResource && item.sublinks?.cta?.[0] && (
											<li className='coop-resourceAll-LinkList'>
												<List bullets='none'>
													{item.sublinks.cta.map((link, index) => (
														<li key={index}>
															<BaseLink data={link} />
														</li>
													))}
												</List>
											</li>
										)}
									</React.Fragment>
								))}
							</List>
						)}

						{pageData.helpful_resources?.[0] && (
							<div className='coop-homeResources sf-my--lg sf-my-1030--xxl'>
								<Container width={1360}>
									{pageData.resources && <h2 className='centerText sf-my--lg sf-my-1030--xxl sf-mb--xxl'>{pageData.resources}</h2>}

									<Row>
										{pageData.helpful_resources.map((col, index) => (
											<Column
												xs={12}
												lg='fluid'
												key={index}
											>
												{col.heading && <p className='coop-homeResource-Heading centerText'>{col.heading}</p>}
												{col.links?.[0] &&
													col.links.map((item, index) => (
														<div
															key={index}
															className='coop-homeResource-ContactInfo centerText'
															dangerouslySetInnerHTML={{ __html: item }}
														/>
													))}
											</Column>
										))}
									</Row>
								</Container>
							</div>
						)}
					</Container>
				</div>
			)}
		</Layout>
	);
};

ResourceLanding.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
};

export default ResourceLanding;

export const query = graphql`
	query ($uid: String!) {
		contentstackResourceLanding(uid: { eq: $uid }) {
			url
			all_resources {
				heading
				group {
					pella_user_only
					icon {
						url
					}
					heading
					sublinks {
						cta {
							...CTA
						}
					}
				}
			}
			ribbons {
				cta_ribbon {
					...CTARibbon
				}
				visibility_role
			}
			resources
			helpful_resources {
				heading
				links
			}
		}
	}
`;
