import React from "react";
import PropTypes from "prop-types";
import { List } from "sash-foundation";
import './anchor.scss';

let smoothScroll = {
	timer: null,
	stop: function () {
		clearTimeout(this.timer);
	},
	scrollTo: function (id, setOffset, targetopOffset, callback) {
		let settings = {
			duration: 1000,
			easing: {
				outQuint: function (x, t, b, c, d) {
					return c*((t=t/d-1)*t*t*t*t + 1) + b;
				}
			}
		};
		let percentage;
		let startTime;
		let node = document.getElementById(id);
		let nodeTop = node.offsetTop;
		let nodeParentTop = node.offsetParent.offsetTop;
			nodeTop = nodeParentTop + nodeTop - 5 + setOffset;
		let nodeHeight = node.offsetHeight;
		let body = document.body;
		let html = document.documentElement;
		let height = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);
		let windowHeight = window.innerHeight-targetopOffset;
		let offset = window.pageYOffset;
		let delta = nodeTop - offset;
		let bottomScrollableY = height - windowHeight;
		let targetY = (bottomScrollableY < delta) ?
			bottomScrollableY - (height - nodeTop - nodeHeight + offset):
			delta;
		startTime = Date.now();
		percentage = 0;

		if (this.timer) {
			clearInterval(this.timer);
		}

		function step () {
			let yScroll;
			let elapsed = Date.now() - startTime;

			if (elapsed > settings.duration) {
				clearTimeout(this.timer);
			}

			percentage = elapsed / settings.duration;
			if (percentage > 1) {
				clearTimeout(this.timer);

				if (callback) {
					callback();
				}
			} else {
				yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
				window.scrollTo(0, yScroll);
				this.timer = setTimeout(step, 10);
			}
		}
		this.timer = setTimeout(step, 10);
	}
};
class AnchorLinks extends React.Component {
	constructor(props){
		super(props);
		let pathname = ""

		if (typeof window !== "undefined"){
			pathname = window.location.hash
		}

		let { offset } = props

		this.state = {
			isValid: true,
			pathname,
			offset : offset ? offset : 0
		};

	}
    scrollToElement = () => {
        const { isValid, pathname, offset } = this.state;
		const path = isValid ? pathname.replace("#","") : "";

		if (path && document.getElementById(path) && typeof window !== "undefined") {
			smoothScroll.scrollTo(path, offset);
		}
	};
	scrollToView = (href, targetopOffset) => {
		event.preventDefault();
		event.stopPropagation();
        const { offset } = this.state;
		const path = href ? href.replace("#","") : "";

		if (typeof window !== "undefined"){
			setTimeout(() => {
				window.history.pushState({},null,"#");
				window.history.pushState({},null,href);
			});
		}

		if (path && document.getElementById(path) && typeof window !== "undefined") {
			smoothScroll.scrollTo(path, offset, targetopOffset);
		}
	}
    componentDidMount() {
	    const { isValid } = this.state;

		if (isValid) this.scrollToElement();
	}
	render(){
		const { data } = this.props;

		if (!Array.isArray(data) || !data.length)
			return null;

		return (
			<List
				bullets='none'
				layout='inline'
				className='anchor-tabs sf-mt--0 sf-py--md'
			>
				{
					data.map((item, index) => (
						<li key={index}>
			                <a
			                    href={item.link.href}
								title={item.link.title}
								data-gtm-linktype={item.gtm_link_type}
								data-gtm-linktext={item.gtm_link_text}
								target={item.target}
								className = 'anchor-link anchor-link-fonts font-size-14'
								onClick = {() => {this.scrollToView(item.link.href)}}
								aria-label={`Anchor Link opens ${item.link.title}`}
			                >
								{ item.link.title }
							</a>
						</li>
					))
				}
			</List>
		);
	}
}

AnchorLinks.propTypes = {
	data: PropTypes.array,
	offset: PropTypes.number,
};

export default AnchorLinks;
