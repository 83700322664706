import React, { Component } from "react";
import { navigate} from "gatsby";
import axios from "axios";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FaFileExcel } from "react-icons/fa";
import moment from "moment";
import sessionStorage from "sessionstorage";

import { Container } from "sash-foundation";

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";
import Nav from "../../../components/common/nav";

import './index.scss';

class CorporateUsers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			corpUsers: [],
			isAdmin: sessionStorage.getItem('userRole') || "",
		};

		this.handleDeactivate = this.handleDeactivate.bind(this);
		this.handleActivate = this.handleActivate.bind(this);
	}

	componentDidMount() {

		if (this.state.isAdmin !== 'ADMIN') {
			navigate('/', { state: {
				prevUrl: window.location.pathname,
				params: window.location.search
			}});
		}

		axios({
			method: "get",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/corpusers`,
			//url: `http://localhost:4000/pella-coop/v1/user/corpusers`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			}
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					corpUsers: response.data.response,
					loading: false
				});
			} else {
				if (response.data.success === false) {
					this.setState({
						corpUsers: [],
						loading: false
					});
				}
			}
		});
	}

	handleDeactivate(user, role) {
		const sendUser = { username: user, userrole: role };
		axios({
			method: "put",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/inactive`,
			//url: `http://localhost:4000/pella-coop/v1/user/inactive`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			},
			data: sendUser
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					corpUsers: response.data.response,
					loading: false
				});
			}
		});
	}
	handleActivate(user, role) {
		const sendUser = { username: user, userrole: role };

		axios({
			method: "put",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/active`,
			//url: `http://localhost:4000/pella-coop/v1/user/active`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			},
			data: sendUser
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					corpUsers: response.data.response,
					loading: false
				});
			}
		});
	}

	exportExcel() {
		axios({
			method: "get",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/corpusersexport`,
			//url: `http://localhost:4000/pella-coop/v1/user/corpusersexport`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			}
		}).then(response => {
			if (response.data.success === true) {
				var userData = response.data.response;

				userData.forEach(a => delete a['Password']);

				/* make the worksheet */
				var ws = XLSX.utils.json_to_sheet(userData);

				/* add to workbook */
				var wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Table1");

				/* write workbook (use type 'binary') */
				var wbout = XLSX.write(wb, {
					bookType: "xlsx",
					type: "binary"
				});

				/* generate a download */
				// eslint-disable-next-line
				function s2ab(s) {
					var buf = new ArrayBuffer(s.length);
					var view = new Uint8Array(buf);
					for (var i = 0; i != s.length; ++i)
						view[i] = s.charCodeAt(i) & 0xff;
					return buf;
				}

				saveAs(
					new Blob([s2ab(wbout)], {
						type: "application/octet-stream"
					}),
					"CorporateUsers_" +
						moment().format("MM_DD_YYYY h_mm A") +
						".xlsx"
				);
			}
		});
	}

	formatDate = (value) => {
		let returnDate = value.toString();
		return moment(returnDate).format('MM/DD/YYYY hh:mm');
	}

	render() {
		const { loading, corpUsers } = this.state;

		return (
			<Layout>
				<SEO title={"Corporate Users"} />

				{/* <Nav /> */}
				<Container className='sf-pb--2xl admin reports' fluid>
					<h1 style={{ fontSize: '2rem' }} className='sf-text--uppercase'>
						Corporate Users
					</h1>
					<hr />
					<div>
						<a
							role='button'
							onClick={this.exportExcel}
							className='sf-mb--md'
						>
							<FaFileExcel alt='Export to Excel' />
							Export to Excel
						</a>
					</div>

					<fieldset className='sf-formGroup'>
						<legend className='sf-display--block'>Corporate Users</legend>
						<div className='data-table-overflow coop-overflow-fix'>
							<table className='data_table'>
								<thead className='data_table_headrow coop-support-SpecsHead'>
									<tr>
										<th className='coop-support-SpecsCell' scope='col'>
											<span className='sr-only'>
												Activate/Deactivate
											</span>
										</th>
										<th className='coop-support-SpecsCell' scope='col'>Active</th>
										<th className='coop-support-SpecsCell' scope='col'>User Role</th>
										<th className='coop-support-SpecsCell' scope='col'>First Name</th>
										<th className='coop-support-SpecsCell' scope='col'>Last Name</th>
										<th className='coop-support-SpecsCell' scope='col'>User Name</th>
										<th className='coop-support-SpecsCell' scope='col'>Email Address</th>
										<th className='coop-support-SpecsCell' scope='col'>Last login</th>

									</tr>
								</thead>
								<tbody>
									{loading
										? null
										: corpUsers.map(function(corpUser, index) {
											return (
												<tr
													key={index}
													className='data_table_row coop-support-SpecsRow'
												>
													<td className='coop-support-SpecsCell'>
														{corpUser.active ==
															"Y" ? (
																<a
																	role='button'
																	onClick={() =>
																		this.handleDeactivate(
																			corpUser.userName,
																			corpUser.userRole
																		)
																	}
																>
																	Deactivate
																</a>
															) : (
																<a
																	role='button'
																	onClick={() =>
																		this.handleActivate(
																			corpUser.userName,
																			corpUser.userRole
																		)
																	}
																>
																	Activate
																</a>
															)}
													</td>
													<td className='coop-support-SpecsCell'>{corpUser.active}</td>
													<td className='coop-support-SpecsCell'>{corpUser.userRole}</td>
													<td className='coop-support-SpecsCell'>
														{corpUser.firstName}
													</td>
													<td className='coop-support-SpecsCell'>{corpUser.lastName}</td>
													<td className='coop-support-SpecsCell'>{corpUser.userName}</td>
													<td className='coop-support-SpecsCell'>{corpUser.email}</td>
													<td className='coop-support-SpecsCell' >{ corpUser.lastLogin !== undefined &&	this.formatDate(corpUser.lastLogin)}</td>
												</tr>
											);
										}, this)}
								</tbody>
							</table>
						</div>
					</fieldset>
				</Container>
			</Layout>
		);
	}
}

export default CorporateUsers;
