import React, {useState} from 'react';
import { Container, Row, Column, TextInput, Button } from "sash-foundation";
import axios from "axios";

import Layout from "../../../components/layout/layout-reset-password";
import SEO from "../../../components/seo";

import "./index.scss";

const ForgotPassword = () => {
	const [value, setValue] = useState('');
	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [showErrors, setShowErrors] = useState(false);
	const [errors, setErrors]  = useState({});

	const handleChange = (event) => {
		setShowSuccess(false);
		setSuccessMessage('');
		setShowErrors(false);
		setErrors('');
		setValue({[event.target.name]: event.target.value});

	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		setShowSuccess(false);
		setSuccessMessage('');
		setShowErrors(false);
		setErrors('');

		axios({
			method: "post",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/password/forgot`,

			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			},
			data: value
		}).then(response => {
			if (response.data.success === true) {
				setSuccessMessage(response.data.message);
				setShowSuccess(true);

			} else {
				if (response.data.success === false) {
					setErrors(response.data.error.message);
					setShowErrors(true);
				}
			}
		});
	};

	return (
		<Layout className='login'>
			<div className='page-topper'></div>
			<SEO title='Reset Password' />
			<Container className='body-background sf-pt--2xl'>
				<Row align='center'>
					<Column xs='12'>
						<h1 className='landing-page-title'>
								Pella Dealer Portal
						</h1>
						{showSuccess &&
							<div className='success_message'>
								<p>{successMessage}</p>
							</div>
						}
						{showErrors &&
							<div className='error_message'>
								<p>{errors}</p>
							</div>
						}
						<div className='login-bg'>
							<div className='login-container'>
								<div className='login-wrapper'>
									<Row>
										<Column xs='12'>
											<h2>Trouble Signing In?</h2>
											<p>Enter your email address and we will send you a confirmation email to reset your password.</p>
											<hr />
										</Column>
									</Row>

									{/* {loginError ? (
										<Row className='error'>
											<Column xs='12'>
												<p>{loginError}</p>
											</Column>
										</Row>
									) : null}
									{errors.userName || errors.password ? (
										<Row className='error'>
											<Column xs='12'>
												<h3 className='error-title'>
														Please correct the
														following error(s):
												</h3>
												{errors.userName ? (
													<p>{errors.userName}</p>
												) : null}
												{errors.password ? (
													<p>{errors.password}</p>
												) : null}
											</Column>
										</Row>
									) : null} */}

									<Row>
										<Column xs='12'>
											<form
												onSubmit={(event) => handleSubmit(event)}
											>
												<TextInput
													type='text'
													id='email'

													labelText='Email Address'
													placeholder='Email Address'
													hideLabel
													onChange={handleChange}
													required
													autocomplete='off'
												></TextInput>
												<Button
													type='submit'
													color='black'
													className='login-button'
												>
														Reset My Password
												</Button>
											</form>
										</Column>
									</Row>
								</div>
							</div>
						</div>
					</Column>
				</Row>
			</Container>

		</Layout>
	);
};

export default ForgotPassword;
