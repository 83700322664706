import React from 'react';
import PropTypes from 'prop-types';
import Container from 'sash-foundation/dist/container';
import BaseImage from '../baseImage/index';

import './index.scss';

const Hero = ({ data, className, children, ...other }) => (
	<div className={`spal-hero ${(data.text_image || !data.image?.[0]) ? 'spal-hero--textOnly' : ''} ${(data.solid_color || data.color) ? 'spal-hero--colorOnly' : ''} ${className || ''}`} {...other}>
		{data.image?.[0] &&
			<BaseImage
				data={data.image[0]}
				className='spal-hero-Image'
			/>
		}

		<Container width={1530} className='spal-hero-Container'>
			<div className='spal-hero-Content'>
				{data.heading &&
					<h1 className='spal-hero-Heading'>{data.heading}</h1>
				}
				{data.subheading &&
					<div
						dangerouslySetInnerHTML={{ __html: data.subheading }}
						className='spal-hero-Description'
					/>
				}
				{children}
			</div>
		</Container>
	</div>
);

Hero.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node
};

export default Hero;