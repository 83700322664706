import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { graphql, navigate } from "gatsby";
import addrs from "email-addresses";
import { Container, Row, Column, Select, Button, TextInput } from "sash-foundation";

import { isLoggedIn } from "../../../components/common/private-route/private-route";

import Layout from "../../../components/layout/layout-register";
import SEO from "../../../components/seo";

import "./index.scss";

class Registration extends Component {
	constructor(props) {
		super(props);

		this.state = {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			password2: "",
			address: "",
			city: "",
			state: "",
			zip: "",
			error: "",
			loading: false,
			errors: [],
			showErrors: false,
			registrationError: ""
		};

		this.handleInput = this.handleInput.bind(this);
		this.submitHandler = this.submitHandler.bind(this);
		this.checkPassword = this.checkPassword.bind(this);
	}

	handleInput(e) {
		this.setState({
			[e.target.name]: e.target.value,
			errors: [],
			registrationError: "",
			showErrors: false
		});
	}

	checkPassword(inputtxt) {
		var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
		if (inputtxt.match(passw)) {
			alert("Correct, try another...");
			return true;
		} else {
			alert("Wrong...!");
			return false;
		}
	}

	validateForm(
		firstName,
		lastName,
		email,
		password,
		password2,
		address,
		city,
		state,
		zip
	) {
		let errors = [];

		if (email.length === 0) {
			errors.email = "Please enter a valid email address.";
		}
		if (email.length > 0) {
			const dealerList = this.props.data.contentstackWhitelist.dealers;
			let domainList = [];

			for (var i = 0; i < dealerList.length; i++) {
				domainList.push(dealerList[i].domain_name);
			}

			const parseEmail = addrs.parseOneAddress(email);
			const emailDomain = parseEmail.domain;

			const hasEmail = domainList.includes(emailDomain) || 'mailinator.com';
			if (!hasEmail) {
				errors.email =
					"The entered email address does not belong to a valid builder group.";
			}
			// if (
			// 	!/@abcsupply.com\s*$/.test(email) &&
			// 	!/@bldr.com\s*$/.test(email) &&
			// 	!/@carterlumber.com\s*$/.test(email) &&
			// 	!/@hammondlumber.com\s*$/.test(email) &&
			// 	!/@mccoys.com\s*$/.test(email) &&
			// 	!/@national-millwork.com\s*$/.test(email) &&
			// 	!/@national-lumber.com\s*$/.test(email) &&
			// 	!/@national-bp.com\s*$/.test(email)
			// ) {
			// 	errors.email =
			// 		"The entered email address does not belong to a valid builder group.";
			// }
		}
		if (firstName.length === 0) {
			errors.firstName = "Please enter your first name.";
		}
		if (lastName.length === 0) {
			errors.lastName = "Please enter your last name.";
		}
		if (password.length === 0) {
			errors.password = "Please enter your desired password.";
		}
		if (password.length > 0) {
			// this.checkPassword(password);
			var passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
			if (!password.match(passwordCheck)) {
				errors.password =
					"Please make sure your desired password is between 8 and 15 characters in length, contains one uppercase letter, one lowercase letter, and one number.";
			}
		}
		if (password2.length === 0) {
			errors.password2 = "Please confirm your desired password.";
		}
		if (password.length > 0 && password2.length > 0) {
			if (!password2.match(password)) {
				errors.password =
					"Please make sure the passwords you entered match.";
			}
		}
		if (address.length === 0) {
			errors.address = "Please enter your address.";
		}
		if (city.length === 0) {
			errors.city = "Please enter your city.";
		}
		if (state.length === 0) {
			errors.state = "Please enter your state.";
		}
		if (zip.length === 0) {
			errors.zip = "Please enter your Zip code.";
		}

		return errors;
	}

	submitHandler(e) {
		e.preventDefault();

		const {
			firstName,
			lastName,
			email,
			password,
			password2,
			address,
			city,
			state,
			zip
		} = this.state;

		const errors = this.validateForm(
			firstName,
			lastName,
			email,
			password,
			password2,
			address,
			city,
			state,
			zip
		);

		if (Object.keys(errors).length > 0) {
			this.setState({
				errors: errors,
				showErrors: true
			});
		} else {
			var companyName = "";
			var companyCode = "";
			const dealerList = this.props.data.contentstackWhitelist.dealers;

			for (var i = 0; i < dealerList.length; i++) {

				const domainName = "@" + dealerList[i].domain_name;
				var reg = new RegExp(domainName);
				var regCheck = reg.test(email);

				if (regCheck) {
					companyName = dealerList[i].company_name;
					companyCode = dealerList[i].company_code;
				}

			}

			// if (/@abcsupply.com\s*$/.test(email)) {
			// 	companyName = "ABC Supply";
			// 	companyCode = "abcsupply";
			// }

			// if (/@bldr.com\s*$/.test(email)) {
			// 	companyName = "Builders FirstSource";
			// 	companyCode = "bldr";
			// }

			// if (/@carterlumber.com\s*$/.test(email)) {
			// 	companyName = "Carter Lumber";
			// 	companyCode = "carterlumber";
			// }

			// if (/@hammondlumber.com\s*$/.test(email)) {
			// 	companyName = "Hammond Lumber Company";
			// 	companyCode = "hammondlumber";
			// }

			// if (/@mccoys.com\s*$/.test(email)) {
			// 	companyName = "McCoys Building Supply";
			// 	companyCode = "mccoys";
			// }

			// if (/@national-millwork.com\s*$/.test(email)) {
			// 	companyName = "National Lumber";
			// 	companyCode = "national-lumber";
			// }

			// if (/@national-lumber.com\s*$/.test(email)) {
			// 	companyName = "National Lumber";
			// 	companyCode = "national-lumber";
			// }

			// if (/@national-bp.com\s*$/.test(email)) {
			// 	companyName = "National Lumber";
			// 	companyCode = "national-lumber";
			// }

			const newUser = {
				userRole: "DLR",
				active: "Y",
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				email: this.state.email,
				password: this.state.password,
				address: this.state.address,
				city: this.state.city,
				state: this.state.state,
				zip: this.state.zip,
				companyName: companyName,
				companyCode: companyCode
			};

			axios({
				method: "post",
				url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/register`,
				//url: `http://localhost:4000/pella-coop/v1/user/register`,
				headers: {
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + process.env.GATSBY_PELLA_API_TOKEN
				},
				data: newUser
			}).then(response => {
				if (response.data.success === true) {
					navigate("/login");
				} else {
					if (response.data.success === false) {
						const errors = [];

						errors.email =
							"A user with that emaill already exists.";
						this.setState({
							errors: errors,
							showErrors: true
						});
					}
				}
			});

			this.setState({
				loading: true
			});
		}
	}

	render() {
		if (isLoggedIn()) {
			navigate("/");
		}

		const { errors, registrationError, showErrors } = this.state;

		return (
			<Layout hideLinks={true} className='registration'>
				<SEO title={"	Online Registration"} />

				<div className='page-topper'></div>
				<Container className='sf-py--2xl admin register-main-section'>
					<h1 style={{ fontSize: '2rem' }} className='sf-text--uppercase'>
						Online Registration
					</h1>
					<hr />

					<fieldset className='sf-formGroup'>
						<legend className='sf-display--block'>Registration Form</legend>
						<Row>
							<Column xs='12'>
								<p style={{ color: '#a70215' }} className='sf-text--bold required-text'>
									All fields required.
								</p>
								<p style={{ color: '#a70215' }} className='sf-text--bold required-note'>
									NOTE: Passwords must be between 8 and 15
									characters in length, contain one uppercase
									letter, one lowercase letter, and one
									number.
								</p>
							</Column>
						</Row>
						{showErrors ? (
							<Row className='error'>
								<Column xs='12'>
									<p>{registrationError}</p>
								</Column>
							</Row>
						) : null}
						{showErrors ? (
							<Row className='error'>
								<Column xs='12'>
									<h3 className='error-title'>
										Please correct the following error(s)
										before proceeding:
									</h3>
									{errors.firstName ? (
										<p>{errors.firstName}</p>
									) : null}
									{errors.lastName ? (
										<p>{errors.lastName}</p>
									) : null}
									{errors.email ? (
										<p>{errors.email}</p>
									) : null}
									{errors.password ? (
										<p>{errors.password}</p>
									) : null}
									{errors.password2 ? (
										<p>{errors.password2}</p>
									) : null}
									{errors.address ? (
										<p>{errors.address}</p>
									) : null}
									{errors.city ? <p>{errors.city}</p> : null}
									{errors.state ? (
										<p>{errors.state}</p>
									) : null}
									{errors.zip ? <p>{errors.zip}</p> : null}
								</Column>
							</Row>
						) : null}
						<form onSubmit={this.submitHandler}>
							<Row>
								<Column xs='12' md='6'>
									{/* One */}
									<TextInput
										type='text'
										id='firstName'
										labelText='First Name'
										placeholder='First Name'
										tabIndex='1'
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='6'>
									<TextInput
										type='text'
										id='address'
										labelText='Address'
										placeholder='Address'
										tabIndex='6'
										onChange={this.handleInput}
									/>
								</Column>
							</Row>
							<Row>
								<Column xs='12' md='6'>
									{/* Two */}
									<TextInput
										type='text'
										id='lastName'
										labelText='Last Name'
										placeholder='Last Name'
										tabIndex='2'
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='6'>
									<TextInput
										type='text'
										id='city'
										labelText='City'
										placeholder='City'
										tabIndex='7'
										onChange={this.handleInput}
									/>
								</Column>
							</Row>
							<Row>
								<Column xs='12' md='6'>
									{/* Three */}
									<TextInput
										type='email'
										id='email'
										labelText='Email Address'
										placeholder='Email Address'
										tabIndex='3'
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='6'>
									<Select
										id='state'
										labelText='State'
										tabIndex='8'
										onChange={this.handleInput}
									>
										<option value='0'>
											--Select Your State--
										</option>
										<option value='Alaska'>Alaska</option>
										<option value='Alabama'>Alabama</option>
										<option value='Arkansas'>
											Arkansas
										</option>
										<option value='Arizona'>Arizona</option>
										<option value='California'>
											California
										</option>
										<option value='Colorado'>
											Colorado
										</option>
										<option value='Connecticut'>
											Connecticut
										</option>
										<option value='District of Columbia'>
											District of Columbia
										</option>
										<option value='Delaware'>
											Delaware
										</option>
										<option value='Florida'>Florida</option>
										<option value='Federal States of Micronesia'>
											Federal States of Micronesia
										</option>
										<option value='Georgia'>Georgia</option>
										<option value='Guam'>Guam</option>
										<option value='Hawaii'>Hawaii</option>
										<option value='Iowa'>Iowa</option>
										<option value='Idaho'>Idaho</option>
										<option value='Illinois'>
											Illinois
										</option>
										<option value='Indiana'>Indiana</option>
										<option value='Kansas'>Kansas</option>
										<option value='Kentucky'>
											Kentucky
										</option>
										<option value='Louisiana'>
											Louisiana
										</option>
										<option value='Massachusetts'>
											Massachusetts
										</option>
										<option value='Maryland'>
											Maryland
										</option>
										<option value='Maine'>Maine</option>
										<option value='Michigan'>
											Michigan
										</option>
										<option value='Minnesota'>
											Minnesota
										</option>
										<option value='Missouri'>
											Missouri
										</option>
										<option value='Mississippi'>
											Mississippi
										</option>
										<option value='Montana'>Montana</option>
										<option value='North Carolina'>
											North Carolina
										</option>
										<option value='North Dakota'>
											North Dakota
										</option>
										<option value='Nebraska'>
											Nebraska
										</option>
										<option value='New Hampshire'>
											New Hampshire
										</option>
										<option value='New Jersey'>
											New Jersey
										</option>
										<option value='New Mexico'>
											New Mexico
										</option>
										<option value='Nevada'>Nevada</option>
										<option value='New York'>
											New York
										</option>
										<option value='Ohio'>Ohio</option>
										<option value='Oklahoma'>
											Oklahoma
										</option>
										<option value='Oregon'>Oregon</option>
										<option value='Pennsylvania'>
											Pennsylvania
										</option>
										<option value='Puerto Rico'>
											Puerto Rico
										</option>
										<option value='Rhode Island'>
											Rhode Island
										</option>
										<option value='South Carolina'>
											South Carolina
										</option>
										<option value='South Dakota'>
											South Dakota
										</option>
										<option value='Tennessee'>
											Tennessee
										</option>
										<option value='Texas'>Texas</option>
										<option value='Utah'>Utah</option>
										<option value='Virginia'>
											Virginia
										</option>
										<option value='Virgin Islands'>
											Virgin Islands
										</option>
										<option value='Vermont'>Vermont</option>
										<option value='Washington'>
											Washington
										</option>
										<option value='Wisconsin'>
											Wisconsin
										</option>
										<option value='West Virginia'>
											West Virginia
										</option>
										<option value='Wyoming'>Wyoming</option>
									</Select>
								</Column>
							</Row>
							<Row>
								<Column xs='12' md='6'>
									{/* Four */}
									<TextInput
										type='password'
										id='password'
										labelText='Password'
										placeholder='Password'
										tabIndex='4'
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='6'>
									<TextInput
										type='text'
										id='zip'
										labelText='Zip Code'
										placeholder='Zip Code'
										tabIndex='9'
										onChange={this.handleInput}
									/>
								</Column>
							</Row>
							<Row>
								<Column xs='12' md='6'>
									{/* Five */}
									<TextInput
										type='password'
										id='password2'
										labelText='Confirm Password'
										placeholder='Confirm Password'
										tabIndex='5'
										onChange={this.handleInput}
									/>
								</Column>
								<Column className='sf-text--right' xs='12' md='6'>
									<Button type='submit' color='black'>
										Register Now
									</Button>
								</Column>
							</Row>
						</form>
					</fieldset>
				</Container>
			</Layout>
		);
	}
}

Registration.propTypes = {
	data: PropTypes.object
};

export default Registration;

export const query = graphql`
{
	contentstackWhitelist {
    dealers {
      domain_name
      company_name
      company_code
      logo
      logourl
    }
  }
}

`;
