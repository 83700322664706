/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Row, Column, TextArea, Button } from "sash-foundation";

const SwatCommentsForm = (props) => {
	const {values, handleViewSection, handleInputChange, expanded} = props;
	const { comments, requestType } = values;

	return (
		<Container>
			<Row>
				<Column xs={12}>
					<label htmlFor='comments'>Additional Comments:</label>
					<TextArea
						name='comments'
						value={comments || ''}
						onChange={(event) => handleInputChange(event)}
						// assistiveText = {'Example:  Jobsite is open from 6am - 3pm, Monday - Friday. Project Manager is John Smith @ 123.456.7890. Walk out basement leads to four stories. Access to home is rough terrain. You will need a heavy duty four wheel drive vehicle. This is a gated community. Must call 30 minutes prior to arrival.'}
					/>
					{requestType !== 'PartsOnly' &&
						<p style={{ fontSize: '0.75rem' }}>
							Example:  Jobsite is open from 6am - 3pm, Monday - Friday. Project Manager is John Smith @ 123.456.7890. Walk out basement leads to four stories. Access to home is rough terrain. You will need a heavy duty four wheel drive vehicle. This is a gated community. Must call 30 minutes prior to arrival.
						</p>
					}
					{requestType === 'PartsOnly' &&
						<p className='font-size-12'>
							{/* Example: Please ship ground */}
						</p>
					}
				</Column>
			</Row>

			<Row className='justify-content-end' >
				{ !expanded &&
					<Button className='sf-mr--md' type='button' color='white' onClick={() => handleViewSection('Attachments')} >Previous</Button>
				}
				<Button color='primary' type='submit'>Submit</Button>
			</Row>
		</Container>
	);
};

export default SwatCommentsForm;
