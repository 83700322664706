/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { navigate, graphql } from "gatsby";
import { BiPlus, BiMinus} from "react-icons/bi";
import sessionStorage from "sessionstorage";

import { Container, Row, Column, TextInput, Button } from "sash-foundation";

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";

import "./index.scss";

const OrderLookup = (props) => {

	const statusData = props.data.contentstackOrderTracking.modular_blocks[0].status;
	const needHelpData = props.data.contentstackOrderTracking.modular_blocks[1].need_help;
	// const promoData = props.data.contentstackOrderTracking.modular_blocks[2].promo.promo_ribbon[0];

	const [poNumbers, setPoNumbers] = useState([]);
	const [newPONumber, setNewPONumber] = useState('');
	const [errors, setErrors] = useState('');
	const [showErrors, setShowErrors] = useState(false);


	const handlePOInput = (e) => {
		const trimmedInput = e.target.value.trim();
		setNewPONumber(trimmedInput);
		setErrors('');
		setShowErrors(false);
	};


	const addPOInput = () => {
		if (newPONumber === '') {
			setErrors({ponum: 'Please enter a P.O. number'});
			setShowErrors(true);
		} else {
			setPoNumbers([...poNumbers, newPONumber]);
			setNewPONumber('');
		}

	};

	const deletePOInput = (index) => {
		let poList = [...poNumbers];
		poList.splice(index, 1);
		setPoNumbers(poList);
	};

	const editItem = (item, index) => {
		let poList = [...poNumbers];
		poList[index] = item;
		setPoNumbers(poList);
	  };

	const validateForm = (ponum, ponumbers) =>{
		let errors = [];

		if(ponumbers.includes('')) {

			let ponumbererrors = [];

			for (let i = 0; i < ponumbers.length; i++) {
				if (ponumbers[i] === '') {
					ponumbererrors.push({"id":i} );
				}

			}

			errors.ponumbers = ponumbererrors;
		}


		if (ponum.length === 0 && ponumbers.length === 0) {
			errors.ponum = "Please provide at least one P.O.#";
		}

		return errors;
	};

	const submitHandler = (e) => {
		e.preventDefault();

		const errors = validateForm(newPONumber, poNumbers);

		if (Object.keys(errors).length > 0) {
			setErrors({ponum: errors.ponum, ponumbers: errors.ponumbers});
			setShowErrors(true);
		} else {
			if (newPONumber && poNumbers.length === 0) {
				sessionStorage.setItem("poNumbers", JSON.stringify([newPONumber]));
				navigate("/orders/orderstatus");
			}
			if (!newPONumber && poNumbers.length > 0) {
				sessionStorage.setItem("poNumbers", JSON.stringify([...poNumbers]));
				navigate("/orders/orderstatus");
			}
			if (newPONumber && poNumbers.length > 0) {
				sessionStorage.setItem("poNumbers", JSON.stringify([...poNumbers, newPONumber]));
				navigate("/orders/orderstatus");
			}
		}
	};
	return (
		<Layout>
			<SEO title='Order Lookup' />

			{/* <Nav /> */}

			<Container  width={1400} className='sf-pb--2xl orders'>
				<div>
					<h1 style={{ fontSize: '2rem', color: '#676767' }} className='sf-text--uppercase sf-text--semibold'>
						Order Lookup
					</h1>
					<hr />

				</div>
				<Row>
					<Column xs='12' sm='12' md='12' lg='8' xl='9'>
						<div className='sf-mb--xl'>
							<div className='content-card'>
								<Row>
									<Column xs='12'>
										<h3>{statusData.heading}</h3>
										<p>{statusData.subheading}</p>
									</Column>
								</Row>
								{/* {showErrors ? (
									<p className='color-red font-weight-bold mt-25'>
										{errors.ponum}
									</p>
								) : null} */}

								<Row>
									<Column xs='12'>
										<div>
											{poNumbers.map((po, index) => (
												<>
													<div className='orders-row sf-display--flex sf-align-items--center' key={index}>
														<TextInput
															type='text'
															id={index}
															name='ponum'
															placeholder='Enter P.O. #'
															labelText='P.O.#'
															onChange={(event) => editItem(event.target.value, index)}
															value={po}
														/>
														<button className='orders-button sf-ml--md' onClick={() => deletePOInput()}><BiMinus /></button>
													</div>
													<div>
														{showErrors && errors?.ponumbers?.length > 0 && errors?.ponumbers?.find(element => element.id === index) &&
															<p className='color-red font-weight-bold mt-0'>Please remove the empty field or add a P.O.#</p>
														}
													</div>

												</>
											))}
											<div className='orders-row sf-display--flex sf-align-items--center'>
												<TextInput
													type='text'
													name='ponum'
													placeholder='Enter P.O. #'
													labelText='P.O.#'
													onChange={(event) => handlePOInput(event)}
													value={newPONumber}
												/>
												<a role='button' className='orders-button-link sf-ml--sm' onClick={() => addPOInput()}><span className='orders-button-icon'><BiPlus /></span> Add P.O.#</a>
											</div>
											{showErrors && errors.ponum && (
												<div>
													<p style={{ color: '#a70215' }} className='sf-text--bold sf-mt--0'>
														{errors.ponum}
													</p>
												</div>

											)}

										</div>
									</Column>
								</Row>
								<Row>
									<Column xs='12'>
										<Button color='black' onClick={(event) => submitHandler(event)}>Submit </Button>
									</Column>
								</Row>
							</div>
						</div>
						{/* <div>
							<div className='cta-card mb-30'>
								<div className='cta-card-image-column'>
									<img src={promoData.background_image.url} alt={promoData.background_image.title} />
								</div>
								<div className='cta-card-text-column'>
									<h3>{promoData.heading}</h3>
									<p>{promoData.subheading}</p>
									<Button href={promoData.cta[0]?.link[0].destination} color='black' gtm-linktype={promoData.cta[0].gtm_link_type} gtm-linktext={promoData.cta[0].cta_title} >{promoData.cta[0].cta_title}</Button>
									<p>{promoData.right_text}</p>
								</div>
							</div>
						</div> */}
					</Column>
					<Column xs='12' sm='12' md='12' lg='4' xl='3'>
						<div className='content-card'>
							<h3>{needHelpData.heading}</h3>
							{needHelpData?.group.map((item, index) => (
								<div className='orders-help-row' key={index}>
									<div> <img className='orders-help-image' src={item.icon?.url} alt={item.icon?.title}/></div>
									<div  dangerouslySetInnerHTML={{ __html: item.description }} />
								</div>
							))}
						</div>
					</Column>
				</Row>
			</Container>
		</Layout>
	);
};

export default OrderLookup;

export const query = graphql`
query  {
	contentstackOrderTracking(uid: {eq: "bltf910ae6b5413107d"}) {
		modular_blocks {
		  status {
			add_po
			box_heading
			heading
			subheading
		  }
		  need_help {
			heading
			group {
			  description
			  icon {
				url
				title
			  }
			}
		  }
		}
	  }
  }
`
