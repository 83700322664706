/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Column, List, Button } from "sash-foundation";

import './index.scss';

const SwatUploadForm = (props) => {
	const { values, setValues, handleViewSection, formType, expanded, errors } = props;
	const { attachment } = values;
	const [previousSection, setPreviousSection] = useState('');

	useEffect(() => {
		if (formType === 'Diagnose') {
			setPreviousSection('FloorInfo');
		} else {
			setPreviousSection('PartsInfo');
		}
	}, [formType]);

	const hiddenFileInput = useRef(null);

	const handleFileClick = (event) => {
		event.preventDefault();
		hiddenFileInput.current.click();
	};

	const handleFiles = (event) => {
		setValues({ ...values, attachment: event.target.files });
	};

	return (
		<Container>
			<Row>
				<Column xs={12}>
					<label htmlFor='attachments'>Selected Files:</label>
					<div className='file-holder'>
						{attachment !== null && attachment.length > 0
							?
							<List bullets='none'>
								{Array.from(attachment).map((file, index) => (
									<li key={index}>{file.name}</li>
								))}
							</List>
							:
							<span>No files selected.</span>
						}
					</div>
					<Button onClick={handleFileClick}>Select Files</Button>
					<input
						type='file'
						id='attachments'
						multiple
						ref={hiddenFileInput}
						onChange={(event) => handleFiles(event)}
						style={{ display: 'none' }} />
				</Column>
			</Row>

			<Row>
				<Column xs={12}>
					<p style={{ fontSize: '0.875rem' }} className='sf-mb--sm'>To select multiple files, hold CTRL while you are making your selections.</p>
					<p style={{ fontSize: '0.75rem' }} className='sf-mt--sm'>Uploads are limited to 10 MB total.</p>
				</Column>
			</Row>

			{
				errors.attachment &&
				<Row><div className='error-text'>{errors.attachment}</div></Row>

			}

			{!expanded &&
				<Row className='sf-justify-content--end'>
					<Button className='sf-mr--md' type='button' color='white' onClick={() => handleViewSection(previousSection)} >Previous</Button>
					<Button color='primary' type='button' onClick={() => handleViewSection('Comments')} >Next</Button>
				</Row>
			}

		</Container>
	);
};

export default SwatUploadForm;
