import React from "react";
import PropTypes from "prop-types";
import Button from "sash-foundation/dist/button";

const BaseButton = ({ data, children, className, ...other }) => {
	const {
		link = [],
		gtm_link_type,
		gtm_link_text,
		ada_title,
		cta_title,
		button_style,
		image = {},
		aria,
		target
	} = data;

	return (
		<Button
			href={link[0].destination}
			color={button_style || 'primary'}
			data-gtm-linktype={gtm_link_type || null}
			data-gtm-linktext={gtm_link_text || null}
			title={ada_title || null}
			target={target}
			rel={target === '_blank' ? 'noopener noreferrer' : null}
			aria-label={aria || null}
			className={className || null}
			{...other}
		>
			{image?.url &&
				<img src={image.url} alt='' className='sf-mr--sm' />
			}
			{children || cta_title || link[0].default_title || ''}
		</Button>
	);
};

BaseButton.propTypes = {
	data: PropTypes.object,
	children: PropTypes.node,
	className: PropTypes.string
};

export default BaseButton;