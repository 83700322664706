import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import AnimateHeight from 'react-animate-height';
import { MdClose, MdRemove, MdAdd } from 'react-icons/md';
import { Container, Row, Column } from 'sash-foundation';
import './index.scss';

const VideoModal = ({ videoId, transcript, title, onClose, isOpen, youtube = true, className, children, ...other }) => {
	const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null;
	const el = typeof document !== `undefined` ? document.createElement('div') : null;

	const [height, setHeight] = useState(0);
	const [collapse, setCollapse] = useState(false);

	useEffect(() => {
		portalRoot.appendChild(el);
		return () => portalRoot.removeChild(el);
	}, [el, portalRoot]);


	const onTranscriptTrigger = () => {
		setHeight(height === 0 ? 'auto' : 0);
		setCollapse(!collapse);
	};

	if (el && isOpen) {
		return createPortal(
			<FocusLock returnFocus={true}>
				<div
					className={`spal-videoModal ${isOpen ? 'spal-videoModal--active' : ''} ${className || ''}`}
					role='dialog'
					aria-hidden={!isOpen}
					{...other}
				>
					<Container width={1000}>
						<button
							className='spal-videoModal-close'
							onClick={onClose}
						>
							<MdClose />
						</button>

						{youtube &&
							<div className='spal-videoModal-iframeContainer'>
								<iframe
									src={`https://www.youtube.com/embed/${videoId}?rel=0&amp;enablejsapi=1`}
									allowFullScreen
									title={title}
								/>
							</div>
						}

						{!youtube && children}

						{transcript &&
							<div className='spal-videoModal-transcriptContainer'>
								<button
									className='spal-videoModal-transcriptTrigger'
									onClick={() => onTranscriptTrigger()}
									aria-expanded={collapse}
								>
									<Row>
										<Column xs='fluid'>{title}</Column>
										<Column xs='auto'>
											{collapse ? <MdRemove /> : <MdAdd />}
										</Column>
									</Row>
								</button>

								<AnimateHeight duration={500} height={height}>
									<div
										className='spal-videoModal-transcriptContent'
										dangerouslySetInnerHTML={{ __html: transcript }}
									/>
								</AnimateHeight>
							</div>
						}
					</Container>
				</div>
			</FocusLock>,
			el
		);
	} else {
		return null;
	}
};

VideoModal.propTypes = {
	data: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	youtube: PropTypes.bool,
	title: PropTypes.string,
	transcript: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node
};

export default VideoModal;
