import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery, Link } from 'gatsby';
import sessionStorage from 'sessionstorage';
import { MdAccountCircle, MdClose, MdExpandLess, MdExpandMore, MdMenu, MdSystemUpdate } from 'react-icons/md';
import { Container, Row, Column, Select } from 'sash-foundation';
import List from 'sash-foundation/dist/list';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { BaseImage, BaseLink } from '../../../components';
import Dropdown from '../../common/Dropdown';

import MobileNav from './mobileNav';

import './header.scss';

const Header = (props) => {
	const staticData = useStaticQuery(query).contentstackMenu2021;
	const [mobileNav, setMobileNav] = useState(false);
	const [activeMenu, setActiveMenu] = useState(null);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [company, setCompany] = useState('');
	const [role, setRole] = useState('');
	const [selectedCompany, setSelectedCompany] = useState(sessionStorage.getItem('selectedCompany') || '');
	const [logo, setLogo] = useState('');
	const [companies, setCompanies] = useState([]);
	const [repCompanies, setRepCompanies] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		if (sessionStorage.getItem('userInfo')) {
			const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

			setFirstName(userInfo.firstName);
			setLastName(userInfo.lastName);
			setCompany(userInfo.company);
			setSelectedCompany(userInfo.company);
			setRole(userInfo.role);
			setRepCompanies(userInfo.repCompanies);

			if (userInfo.company === 'Pella') {
				if (sessionStorage.getItem('selectedCompany')) {
					setSelectedCompany(sessionStorage.getItem('selectedCompany'));
				}
			}
		}
	}, []);

	useEffect(() => {
		if (repCompanies && repCompanies.length > 0 && sessionStorage.getItem('selectedCompany') == null) {
			const selected = repCompanies[0].id || '';
			sessionStorage.setItem('selectedCompany', selected);
			setSelectedCompany(selected);
		}
	}, [repCompanies]);

	useEffect(() => {
		const abcsup = 'https://images.contentstack.io/v3/assets/blteffeb5ee36a60eab/blt556a2a6c03b08a73/abc-supply-logo.png';
		const buildfirst = 'https://images.contentstack.io/v3/assets/blteffeb5ee36a60eab/blt89aa1171560df779/builders-first-source-logo.png';
		const carter = 'https://images.contentstack.io/v3/assets/blteffeb5ee36a60eab/blt11baa8f8d436f344/carter-lumber-logo.png?pad=5&bg-color=ffffff';
		const hammond = 'https://images.contentstack.io/v3/assets/blteffeb5ee36a60eab/blt3846bd1a062810d4/hammond-lumber-company-logo.png';
		const mccoy = 'https://images.contentstack.io/v3/assets/blteffeb5ee36a60eab/blt0873169eeea73c3d/mccoys-building-supply-logo.png';
		const natlum = 'https://images.contentstack.io/v3/assets/blteffeb5ee36a60eab/blt86f5fab2eef77fe4/national-lumber.jpg?width=205';
		const alliance = 'https://images.contentstack.io/v3/assets/blteffeb5ee36a60eab/bltb2e78e19a484acce/organizations_Alliance_lumber_56.jpg?width=90';

		if (role === 'ADMIN') {
			setCompanies([
				{ id: 'ABC Supply', name: 'ABC Supply Co.Inc.' },
				{ id: 'Beacon Building Products', name: 'Beacon Building Products' },
				{ id: 'Builders FirstSource', name: 'Builder’s FirstSource' },
				{ id: 'Carter Lumber', name: 'Carter Lumber' },
				{
					id: 'Hammond Lumber Company',
					name: 'Hammond Lumber Company',
				},
				{
					id: 'McCoys Building Supply',
					name: 'McCoy’s Building Supply',
				},
				{ id: 'Admin', name: 'Admin' },
				{ id: 'National Lumber', name: 'National Lumber' },
				{ id: 'Alliance Lumber', name: 'Alliance Lumber' },
				{ id: 'Pella', name: 'Pella Pro' },
			]);
		}

		if (role === 'REP' || role === 'DSM') {
			const companyID = repCompanies && repCompanies.length > 0 ? repCompanies[0].id : '';
			setCompanies(repCompanies);

			if (companyID === 'ABC Supply') {
				setLogo(abcsup);
			}

			if (companyID === 'Beacon Building Products') {
				setLogo('');
			}

			if (companyID === 'Builders FirstSource') {
				setLogo(buildfirst);
			}

			if (companyID === 'Carter Lumber') {
				setLogo(carter);
			}

			if (companyID === 'Hammond Lumber Company') {
				setLogo(hammond);
			}

			if (companyID === 'McCoys Building Supply') {
				setLogo(mccoy);
			}

			if (companyID === 'National Lumber') {
				setLogo(natlum);
			}

			if (companyID === 'Alliance Lumber') {
				setLogo(alliance);
			}

			if (companyID === 'Pella') {
				setLogo('');
			}
		}

		if (selectedCompany === 'ABC Supply') {
			setLogo(abcsup);
		}

		if (selectedCompany === 'Beacon Building Products') {
			setLogo('');
		}

		if (selectedCompany === 'Builders FirstSource') {
			setLogo(buildfirst);
		}

		if (selectedCompany === 'Carter Lumber') {
			setLogo(carter);
		}

		if (selectedCompany === 'Hammond Lumber Company') {
			setLogo(hammond);
		}

		if (selectedCompany === 'McCoys Building Supply') {
			setLogo(mccoy);
		}

		if (selectedCompany === 'National Lumber') {
			setLogo(natlum);
		}

		if (selectedCompany === 'Alliance Lumber') {
			setLogo(alliance);
		}

		if (selectedCompany === 'Pella') {
			setLogo('');
		}

		setUpdate(true);
	}, [role, selectedCompany]);

	useEffect(() => {
		if (update) {
			setUpdate(false);
		}
	}, [update]);

	const onSelectChange = (e) => {
		setSelectedCompany(e.target.value);
		sessionStorage.setItem('selectedCompany', e.target.value);
		setUpdate(true);
		window.location.reload();
	};

	const onLogoutClick = (e) => {
		e.preventDefault();
		sessionStorage.clear();
		navigate('/login/');
	};

	const onMobileClick = () => {
		setMobileNav(!mobileNav);
	};

	const onNavTabClick = (i) => setActiveMenu(i);

	return (
		<header className='coop-header'>
			<Container fluid={true}>
				<div className='coop-headerGrid'>
					<div className='coop-headerGrid-Logo sf-display--flex sf-align-items--center sf-pb--sm sf-pb-1290--0'>
						{staticData?.home_logo?.url && (
							<a
								className='coop-headerSpacing'
								href='/'
							>
								<BaseImage
									data={{ image: { url: staticData.home_logo.url }, image_alt: '' }}
									lazy={false}
								/>
							</a>
						)}
						{logo !== '' && (
							<a
								href='/'
								className='sf-display--inline'
							>
								<BaseImage
									data={{ image: { url: logo }, image_alt: '' }}
									lazy={false}
									className='sf-width--100'
								/>
							</a>
						)}
					</div>

					{staticData?.global?.global_cta?.[0] && !props.hideLinks && (
						<List
							bullets='none'
							layout='inline'
							className='coop-headerGrid-Global'
						>
							<li className='coop-headerGrid-GlobalItem'>
								<MdAccountCircle />
								Welcome, {firstName}
							</li>
							<li className='coop-headerGrid-GlobalItem'>
								<button onClick={(e) => onLogoutClick(e)}>Logout</button>
							</li>
							{role !== 'DLR' ? (
								<li className='coop-headerGrid-GlobalItem-1 coop-select'>
									<Select
										id='ddlDivision'
										className=''
										labelText='Select Company'
										hideLabel={true}
										onChange={(e) => onSelectChange(e)}
										value={selectedCompany}
									>
										{/* <option key={""} value={""}>Default Company</option> */}
										{companies &&
											companies.length > 0 &&
											companies.map((option, index) => (
												<option
													key={index}
													value={option.id}
												>
													{option.name}
												</option>
											))}
									</Select>
								</li>
							) : null}
						</List>
					)}

					{/* {staticData?.navigation_section?.navigation?.[0] &&
						<List bullets='none' layout='inline' className='coop-headerGrid-Nav'>
							{staticData.navigation_section.navigation.map((item, index) => (
								<li key={index}>
									{item.heading_cta?.[0] &&
										<BaseLink data={item.heading_cta[0]} className='coop-headerGrid-NavLink' />
									}
									{!item.heading_cta?.[0] && item.heading &&
										<>
											<button className='coop-headerGrid-NavLink'>
												{item.heading}
												<MdKeyboardArrowDown />
											</button>
										</>
									}
								</li>
							))}
						</List>
					} */}

					{staticData?.navigation_section?.navigation?.[0] && !props.hideLinks && (
						<List
							bullets='none'
							layout='inline'
							className='coop-navigation textCenter'
						>
							{staticData.navigation_section.navigation.map((item, index) => (
								<li key={index}>
									{item.heading && item.heading === 'Reports' && role === 'ADMIN' && (
										<Dropdown
											data={item}
											className='coop-navItem'
											setActive={onNavTabClick}
										>
											<Container width={1530}>
												{item.has_subnav && (
													<div className='coop-supportMenu'>
														<List bullets='none'>
															{item.sub_navigation.reference?.[0] &&
																item.sub_navigation.reference.map((link, index) => (
																	<li key={index}>
																		<BaseLink data={link} />
																	</li>
																))}
														</List>
													</div>
												)}
											</Container>
										</Dropdown>
									)}
									{item.heading && item.heading !== 'Reports' && (
										<Dropdown
											data={item}
											className='coop-navItem'
											setActive={onNavTabClick}
										>
											<Container width={1530}>
												{item.has_subnav && (
													<div className='coop-supportMenu'>
														<List bullets='none'>
															{item.sub_navigation.reference?.[0] &&
																item.sub_navigation.reference.map((link, index) => (
																	<li key={index}>
																		<BaseLink data={link} />
																	</li>
																))}
														</List>
													</div>
												)}
											</Container>
										</Dropdown>
									)}
									{!item.heading && item.heading_cta && item.heading_cta[0].link[0].default_title !== 'User Admin' && (
										<BaseLink
											data={item.heading_cta[0]}
											className='coop-navItem'
										/>
									)}
									{!item.heading && item.heading_cta && item.heading_cta[0].link[0].default_title === 'User Admin' && role === 'ADMIN' && (
										<BaseLink
											data={item.heading_cta[0]}
											className='coop-navItem'
										/>
									)}
								</li>
							))}
						</List>
					)}

					<div className={props.hideLinks ? 'coop-headerGrid-Search-Auth sf-display--none sf-display-1290--block' : 'coop-headerGrid-Search sf-display--none sf-display-1290--block'}>
						{staticData?.global?.global_cta?.[0] && (
							<List
								bullets='none'
								layout='inline'
								className='coop-headerGrid-Global'
							>
								<li>{staticData.global.heading}</li>
								{staticData.global.global_cta.map((item, index) => (
									<li key={index}>
										<BaseLink data={item} />
									</li>
								))}
								{props.hideLinks && (
									<li>
										<Link to='/login/'>Sign In</Link>
									</li>
								)}
							</List>
						)}
					</div>

					<button className='coop-headerGrid-MobileBtn coop_mobileBtns'>
						<MdAccountCircle />
						<span>{firstName}</span>
					</button>

					<button
						onClick={() => onMobileClick()}
						className='coop-headerGrid-MobileBtn coop_mobileBtns coop_lastBtn'
					>
						{!mobileNav ? <MdMenu /> : <MdClose />}
						<span>Menu</span>
					</button>
				</div>
			</Container>
			<Container fluid={true}>
				{!props.TopCTA && staticData.subnav?.resources?.[0] && !props.hideLinks && (
					<div className='sf-display--none sf-display-1290--block coop-homeResourceMenu'>
						<Container width={1500}>
							<Accordion
								allowMultipleExpanded={true}
								allowZeroExpanded={true}
							>
								<AccordionItem uuid='1'>
									<AccordionItemHeading className='coop-mobileNav-Btn'>
										<AccordionItemButton>
											{staticData.subnav.heading}
											<MdExpandMore className='coop-menu-expandIconMore' /> <MdExpandLess className='coop-menu-expandIconLess' />
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel className='itemPanel'>
										<List
											bullets='none'
											className='coop-resourceAll-List'
										>
											{staticData.subnav.resources.map((item, index) => (
												<React.Fragment key={index}>
													<li className=''>
														<Link className={'coop-resourceAll-Btn1 coop-resourceAll-Btn--inactive1'}>
															<BaseLink data={item.cta[0]} />
														</Link>
													</li>
												</React.Fragment>
											))}
										</List>
									</AccordionItemPanel>
								</AccordionItem>
							</Accordion>
						</Container>
					</div>
				)}
				{props.TopCTA && staticData.subnav?.resources?.[0] && !props.hideLinks && (
					<div className='coop-homeResourceMenu'>
						<Container width={1500}>
							<div
								className='coop_subnavHeading'
								dangerouslySetInnerHTML={{ __html: staticData.subnav.heading }}
							/>
							<List
								bullets='none'
								className='coop-resourceAll-List'
							>
								{staticData.subnav.resources.map((item, index) => (
									<React.Fragment key={index}>
										<li className=''>
											<Link className={'coop-resourceAll-Btn1 coop-resourceAll-Btn--inactive1'}>
												<BaseLink data={item.cta[0]} />
											</Link>
										</li>
									</React.Fragment>
								))}
							</List>
						</Container>
					</div>
				)}
				{/* <List bullets='none' layout='fluid'>
								{staticData.subnav.resources.map((item, index) => (
									<li key={index}>
										{item.cta?.[0] && <BaseLink data={item.cta[0]} />}
									</li>
								))}
							</List> */}
			</Container>
			{mobileNav && staticData?.navigation_section?.navigation?.[0] && (
				<MobileNav
					data={staticData.navigation_section.navigation}
					gData={staticData.global}
					logoutTrigger={onLogoutClick}
					changeTrigger={onSelectChange}
					role={role}
					selectedCompany={selectedCompany}
					companies={companies}
				/>
			)}
		</header>
	);
};

Header.propTypes = {
	pageURL: PropTypes.string,
	TopCTA: PropTypes.bool,
	hideLinks: PropTypes.bool,
};

export default Header;

export const query = graphql`
	query {
		contentstackMenu2021 {
			home_logo {
				url
			}
			subnav {
				box_color
				heading
				resources {
					cta {
						...CTA
					}
					pro_type {
						title
						tag_name
						tag_type
					}
				}
			}
			navigation_section {
				navigation {
					heading
					heading_cta {
						...CTA
					}
					has_subnav
					sub_navigation {
						reference {
							...CTA
						}
					}
				}
			}
			global {
				heading
				global_cta {
					...CTA
				}
			}
		}
	}
`;
