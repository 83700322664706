import React, { Component } from 'react';
import axios from 'axios';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FaFileExcel } from 'react-icons/fa';
import moment from 'moment';
import sessionStorage from "sessionstorage";
import { navigate} from "gatsby";

import { Button, Container, TextInput, Modal, Select, Alert } from 'sash-foundation';

import Layout from '../../../components/layout/layout';
import SEO from '../../../components/seo';

import './index.scss';

class RegisteredUsers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			registeredUsers: [],
			exportregisteredUsers: [],
			editUserModalOpen: false,
			modalLoading: false,
			modalMessage:'',
			isAdmin: sessionStorage.getItem('userRole') || "",
			displayAlert: false,
			alertType: '',
			alertMessage: '',
			removeUserUserId: '',
			removeUserModalOpen: false,
			states: [
				{ name: 'Alabama' },
				{ name: 'Alaska' },
				{ name: 'Arizona' },
				{ name: 'Arkansas' },
				{ name: 'California' },
				{ name: 'Colorado' },
				{ name: 'Connecticut' },
				{ name: 'Delaware' },
				{ name: 'Florida' },
				{ name: 'Georgia' },
				{ name: 'Hawaii' },
				{ name: 'Idaho' },
				{ name: 'Illinois' },
				{ name: 'Indiana' },
				{ name: 'Iowa' },
				{ name: 'Kansas' },
				{ name: 'Kentucky' },
				{ name: 'Louisiana' },
				{ name: 'Maine' },
				{ name: 'Maryland' },
				{ name: 'Massachusetts' },
				{ name: 'Michigan' },
				{ name: 'Minnesota' },
				{ name: 'Mississippi' },
				{ name: 'Missouri' },
				{ name: 'Montana' },
				{ name: 'Nebraska' },
				{ name: 'Nevada' },
				{ name: 'New Hampshire' },
				{ name: 'New Jersey' },
				{ name: 'New Mexico' },
				{ name: 'New York' },
				{ name: 'North Carolina' },
				{ name: 'North Dakota' },
				{ name: 'Ohio' },
				{ name: 'Oklahoma' },
				{ name: 'Oregon' },
				{ name: 'Pennsylvania' },
				{ name: 'Rhode Island' },
				{ name: 'South Carolina' },
				{ name: 'South Dakota' },
				{ name: 'Tennessee' },
				{ name: 'Texas' },
				{ name: 'Utah' },
				{ name: 'Vermont' },
				{ name: 'Virginia' },
				{ name: 'Washington' },
				{ name: 'West Virginia' },
				{ name: 'Wisconsin' },
				{ name: 'Wyoming' }
			]
		};

		this.handleInput = this.handleInput.bind(this);
		this.handleDeactivate = this.handleDeactivate.bind(this);
		this.handleActivate = this.handleActivate.bind(this);
		this.exportExcel = this.exportExcel.bind(this);
		this.onEditUserModalTrigger = this.onEditUserModalTrigger.bind(this);
		this.editUserCloseClick = this.editUserCloseClick.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.divRef = React.createRef();
		this.onAlertCloseClick = this.onAlertCloseClick.bind(this);
		this.removeUser = this.removeUser.bind(this);
		this.onRemoveUserModalTrigger = this.onRemoveUserModalTrigger.bind(this);
		this.removeUserCloseClick = this.removeUserCloseClick.bind(this);
	}

	componentDidMount() {

		if (this.state.isAdmin !== 'ADMIN') {
			navigate('/', { state: {
				prevUrl: window.location.pathname,
				params: window.location.search
			}});
		}

		axios({
			method: 'get',
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/registeredusers`,
			//url: `http://localhost:4000/pella-coop/v1/user/registeredusers`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
			}
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					registeredUsers: response.data.response,
					loading: false
				});
			} else {
				if (response.data.success === false) {
					this.setState({
						registeredUsers: [],
						loading: false
					});
				}
			}
		});
	}

	handleDeactivate(user, role) {
		const sendUser = { username: user, userrole: role };

		axios({
			method: 'put',
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/inactive`,
			//url: `http://localhost:4000/pella-coop/v1/user/inactive`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
			},
			data: sendUser
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					registeredUsers: response.data.response,
					loading: false
				});
			}
		});
	}
	handleActivate(user, role) {
		const sendUser = { username: user, userrole: role };

		axios({
			method: 'put',
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/active`,
			//url: `http://localhost:4000/pella-coop/v1/user/active`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
			},
			data: sendUser
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					registeredUsers: response.data.response,
					loading: false
				});
			}
		});
	}

	handleInput(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	onEditUserModalTrigger(user) {
		this.setState({
			editUserModalOpen: true,
			editUserUserName: user,
			modalLoading: true
		});
		axios({
			method: 'get',
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/registereduser/${user}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
			}
		}).then(response => {
			const {
				firstName,
				lastName,
				userName,
				address,
				city,
				state,
				zip,
			} = response.data.response;
			this.setState({
				editUserFirstName: firstName,
				editUserLastName: lastName,
				editUserUserName: userName,
				editUserAddress: address,
				editUserCity: city,
				editUserState: state,
				editUserZip: zip,
				editUserNewPassword: '',
				modalLoading: false
			});
		});
	}

	updateUser() {
		this.setState({
			modalLoading: true
		});
		const {
			editUserUserName,
			editUserFirstName,
			editUserLastName,
			editUserAddress,
			editUserCity,
			editUserState,
			editUserZip,
			editUserPassword,
			editUserNewPassword
		} = this.state;

		let changePassword = '';
		editUserNewPassword
			? (changePassword = editUserNewPassword)
			: (changePassword = editUserPassword);

		const data = {
			userName: editUserUserName,
			firstName: editUserFirstName,
			lastName: editUserLastName,
			address: editUserAddress,
			city: editUserCity,
			state: editUserState,
			zip: editUserZip,
			password: changePassword
		};
		axios
			.put(
				`${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/registereduser`,
				data,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
					}
				}
			)
			.then(response => {

				if (response.data.success === true) {

					const { firstName, lastName, address, city, state, zip, password} = response.data.response;
					this.setState({
						editUserFirstName: firstName,
						editUserLastName: lastName,
						editUserAddress: address,
						editUserCity: city,
						editUserState: state,
						editUserZip: zip,
						editUserPassword: password,
						modalLoading: false,
						modalMessage: "Success! The user's information has been updated."
					});
					this.divRef.current.scrollIntoView({ behavior: 'smooth' });
				} else {
					this.setState({
						modalLoading: false,
						modalMessage: "Oops! Something went wrong."

					});
				}
			});
	}

	editUserCloseClick() {
		this.setState({
			editUserModalOpen: false
		});
		window.location.reload(false);

	}

	exportExcel() {
		axios({
			method: 'get',
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/registeredusersexport`,
			//url: `http://localhost:4000/pella-coop/v1/user/registeredusersexport`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
			}
		}).then(response => {
			if (response.data.success === true) {
				var userData = response.data.response;

				userData.forEach(a => delete a['Password']);

				/* make the worksheet */
				var ws = XLSX.utils.json_to_sheet(userData);

				/* add to workbook */
				var wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, 'Table1');

				/* write workbook (use type 'binary') */
				var wbout = XLSX.write(wb, {
					bookType: 'xlsx',
					type: 'binary'
				});

				/* generate a download */
				// eslint-disable-next-line
				function s2ab(s) {
					var buf = new ArrayBuffer(s.length);
					var view = new Uint8Array(buf);
					for (var i = 0; i != s.length; ++i)
						view[i] = s.charCodeAt(i) & 0xff;
					return buf;
				}

				saveAs(
					new Blob([s2ab(wbout)], {
						type: 'application/octet-stream'
					}),
					'RegisteredUsers_' +
						moment().format('MM_DD_YYYY h_mm A') +
						'.xlsx'
				);
			}
		});
	}

	formatDate = (value) => {
		let returnDate = value.toString();
		return moment(returnDate).format('MM/DD/YYYY hh:mm');
	}

	onRemoveUserModalTrigger(userId){
		this.setState({
			removeUserModalOpen: true,
			removeUserUserId: userId,
		});
	}

	removeUser (userId) {

		this.setState({
			loading: true,
			removeUserModalOpen:false
		});
		axios({
			method: 'delete',
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/registereduser/${userId}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
			},
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					registeredUsers: response.data.response,
					displayAlert: true,
					alertType:'success',
					alertMessage: 'The user was removed successfully.',
					loading: false
				});
			}
			if (response.data.success === false) {
				this.setState({
					displayAlert: true,
					alertType:'error',
					alertMessage: response.data.message,
					loading: false
				});
			}
		});
	}

	removeUserCloseClick() {
		this.setState({
			removeUserModalOpen: false
		});
	}

	onAlertCloseClick() {
		this.setState({
			displayAlert: false
		});
	}

	render() {
		const { loading, registeredUsers, modalLoading, modalMessage, displayAlert } = this.state;

		return (
			<Layout>
				<SEO title={'Registered Users'} />

				{/* <Nav /> */}
				<Container className='sf-pb--2xl admin reports' fluid>
					<h1 style={{ fontSize: '2rem' }} className='sf-text--uppercase'>
						Registered Users
					</h1>
					<hr />

					{displayAlert &&  <Alert  type={this.state.alertType} onClose={this.onAlertCloseClick}>{this.state.alertMessage}</Alert>}

					<fieldset className='sf-formGroup coop-borderNone'>
						<legend className='sf-display--block required'>Registered Users</legend>
						<div>
							<a onClick={this.exportExcel} className='sf-mb--md'>
								<FaFileExcel alt='Export to Excel' />
								Export to Excel
							</a>
						</div>

						{loading ? (
							<div id='overlay' className='spinner'>
								<img
									src={require('../../../images/spinner.svg')}
								/>
							</div>
						) : (
							<div className='data-table-overflow coop-table-overflow'>
								<table className='data_table'>
									<thead className='data_table_headrow coop-support-SpecsHead1'>
										<tr>
											<th className='coop-support-SpecsCell' scope='col'>
												<span className='sr-only'>
													Edit
												</span>
											</th>
											<th className='coop-support-SpecsCell' scope='col'>
												<span className='sr-only'>
													Remove
												</span>
											</th>
											<th className='coop-support-SpecsCell' scope='col'>
												<span className='sr-only'>
													Activate/Deactivate
												</span>
											</th>
											<th className='coop-support-SpecsCell' scope='col'>Active</th>
											<th className='coop-support-SpecsCell' scope='col'>First Name</th>
											<th className='coop-support-SpecsCell' scope='col'>Last Name</th>
											<th className='coop-support-SpecsCell' scope='col'>User Name</th>
											<th className='coop-support-SpecsCell' scope='col'>Email Address</th>
											<th className='coop-support-SpecsCell' scope='col'>Address</th>
											<th className='coop-support-SpecsCell' scope='col'>City</th>
											<th className='coop-support-SpecsCell' scope='col'>State</th>
											<th className='coop-support-SpecsCell' scope='col'>Zip</th>
											<th className='coop-support-SpecsCell' scope='col'>Last login</th>
										</tr>
									</thead>
									<tbody>
										{loading
											? null
											: registeredUsers.map(function(
												registeredUser,
												index
											) {
												return (
													<tr
														key={index}
														className='data_table_row coop-support-SpecsRow'
													>

														<td className='coop-support-SpecsCell' >
															<a
																role='button'
																onClick={() =>
																	this.onEditUserModalTrigger(
																		registeredUser.userName
																	)
																}
															>
																Edit
															</a>
														</td>
														<td className='coop-support-SpecsCell' >
															<a role='button' onClick={() => this.onRemoveUserModalTrigger(registeredUser._id)}>Remove</a>
														</td>
														<td className='coop-support-SpecsCell' >
															{registeredUser.active ==
																'Y' ? (
																	<a
																		role='button'
																		onClick={() =>
																			this.handleDeactivate(
																				registeredUser.userName,
																				registeredUser.userRole
																			)
																		}
																	>
																		Deactivate
																	</a>
																) : (
																	<a
																		role='button'
																		onClick={() =>
																			this.handleActivate(
																				registeredUser.userName,
																				registeredUser.userRole
																			)
																		}
																	>
																		Activate
																	</a>
																)}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.active
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.firstName
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.lastName
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.userName
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.email
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.address
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.city
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.state
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.zip
															}
														</td>
														<td className='coop-support-SpecsCell' >
															{
																registeredUser.lastLogin !== undefined &&	this.formatDate(registeredUser.lastLogin)
															}
														</td>
													</tr>
												);
											},
											this)}
									</tbody>
								</table>
							</div>
						)}
					</fieldset>
				</Container>
				<Modal
					dialog
					isOpen={this.state.editUserModalOpen}
					onClose={this.editUserCloseClick}
				>
					<div ref={this.divRef}>
						<h2>Edit User</h2>
						<div>
							<Container>
								{modalLoading ? (
									<div id='overlay' className='spinner'>
										<img
											src={require('../../../images/spinner.svg')}
										/>
									</div>
								) : null}
								<p className='color-red font-weight-bold'>{modalMessage}</p>
								<form>
									<TextInput
										type='text'
										id='editUserUserName'
										labelText='Username'
										value={
											this.state.editUserUserName || ''
										}
										disabled
									></TextInput>
									<TextInput
										type='text'
										id='editUserFirstName'
										labelText='First Name'
										value={
											this.state.editUserFirstName || ''
										}
										onChange={this.handleInput}
									></TextInput>
									<TextInput
										type='text'
										id='editUserLastName'
										labelText='Last Name'
										value={
											this.state.editUserLastName || ''
										}
										onChange={this.handleInput}
									></TextInput>
									<TextInput
										type='text'
										id='editUserAddress'
										labelText='Address'
										placeholder='Address'
										value={this.state.editUserAddress || ''}
										onChange={this.handleInput}
									></TextInput>
									<TextInput
										type='text'
										id='editUserCity'
										labelText='City'
										placeholder='City'
										value={this.state.editUserCity || ''}
										onChange={this.handleInput}
									></TextInput>
									<Select
										id='editUserState'
										labelText='State'
										required={true}
										onChange={this.handleInput}
										value={
											this.state.editUserState ||
											'-- Select ---'
										}
									>
										{this.state.states.map(
											(state, index) => (
												<option
													key={index}
													value={state.name}
												>
													{state.name}
												</option>
											)
										)}
									</Select>
									<TextInput
										type='text'
										id='editUserZip'
										labelText='Zip'
										placeholder='Zip'
										value={this.state.editUserZip || ''}
										onChange={this.handleInput}
									></TextInput>
									<TextInput
										type='text'
										id='editUserNewPassword'
										labelText='New Password'
										placeholder='New Password'
										value={
											this.state.editUserNewPassword || ''
										}
										onChange={this.handleInput}
									></TextInput>
								</form>
							</Container>
						</div>
						<div className='sf-text--right'>
							<Button
								color='primary'
								onClick={() => this.updateUser()}
								className='sf-mr--md'
							>
								Update
							</Button>
							<Button
								color='black'
								onClick={this.editUserCloseClick}
							>
								Close
							</Button>
						</div>
					</div>
				</Modal>
				<Modal isOpen={this.state.removeUserModalOpen} onClose={this.editUserCloseClick}>
					<div>
						<Container>
							<h2>Confirm user removal</h2>
						</Container>
					</div>
					<div className='sf-text--right'>
						<Button color='primary' className='sf-mr--md' onClick={() => this.removeUser(this.state.removeUserUserId)}>Remove</Button>
						<Button color='black'onClick={this.removeUserCloseClick} >Cancel</Button>
					</div>
				</Modal>
			</Layout>
		);
	}
}

export default RegisteredUsers;
