/* eslint-disable react/prop-types */
import React, { useState, useEffect} from 'react';
import { Container, Row, Column, Button, TextArea, Checkbox, Select, TextInput } from "sash-foundation";

import './index.scss';

const SwatStoreForm = (props) => {
	const { values, errors, handleViewSection, handleInputChange, onBlur, expanded} = props;

	const { storeNameNumber, storeContactName, bestContactNumber, storeContactEmail, jobName, originalPONumber, newPONumber, lineNumberAffected, issueDesc, bestTextNumber, requestType, deliverParts, shippingName, shippingAddress, shippingCity, shippingState, shippingZip, shippingPhone} = values;

	return (

		<Container>
			<Row>
				<Column xs={12}>
					<div className='required-row'><span style={{ color: '#a70215' }}>*</span> <span>required fields</span></div>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='storeNameNumber' className='required'> Store Name and Number:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						id='storeNameNumber'
						name='storeNameNumber'
						value={storeNameNumber || ''}
						onChange={(event) => handleInputChange(event)}
						type='text'
						aria-required='true'
						autoComplete='off'
						onBlur={(event) => onBlur(event)}
						error={errors.storeNameNumber ? true : false}
						errorText={errors.storeNameNumber}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='storeContactName' className='required'>Store Contact Name:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='storeContactName'
						id='storeContactName'
						value={storeContactName || ''}
						onChange={(event) => handleInputChange(event)}
						type='text'
						aria-required='true'
						autoComplete='off'
						onBlur={(event) => onBlur(event)}
						error={errors.storeContactName ? true : false}
						errorText={errors.storeContactName}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='bestContactNumber' className='required'>Best Contact Number:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='bestContactNumber'
						id='bestContactNumber'
						value={bestContactNumber || ''}
						onChange={(event) => handleInputChange(event)}
						type='text'
						aria-required='true'
						autoComplete='off'
						onBlur={(event) => onBlur(event)}
						error={errors.bestContactNumber ? true : false}
						errorText={errors.bestContactNumber}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='storeContactEmail' className='required'>Store Contact Email:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='storeContactEmail'
						id='storeContactEmail'
						value={storeContactEmail || ''}
						onChange={(event) => handleInputChange(event)}
						type='text'
						aria-required='true'
						autoComplete='off'
						onBlur={(event) => onBlur(event)}
						error={errors.storeContactEmail ? true : false}
						errorText={errors.storeContactEmail}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobName' className='required'>Job Name:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobName'
						id='jobName'
						value={jobName || ''}
						onChange={(event) => handleInputChange(event)}
						type='text'
						aria-required='true'
						autoComplete='off'
						onBlur={(event) => onBlur(event)}
						error={errors.jobName ? true : false}
						errorText={errors.jobName}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='originalPONumber' className='required'>Original PO Number:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='originalPONumber'
						id='originalPONumber'
						value={originalPONumber || ''}
						onChange={(event) => handleInputChange(event)}
						type='text'
						aria-required='true'
						autoComplete='off'
						onBlur={(event) => onBlur(event)}
						error={errors.originalPONumber ? true : false}
						errorText={errors.originalPONumber}
					/></Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='newPONumber'>New PO # (if needed):</label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='newPONumber'
						id='newPONumber'
						value={newPONumber || ''}
						onChange={(event) => handleInputChange(event)}
						type='text'
						aria-required='true'
						autoComplete='off'
						onBlur={(event) => onBlur(event)}
						error={errors.newPONumber ? true : false}
						errorText={errors.newPONumber}
					/></Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='lineNumberAffected'>Line Number Affected:</label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='lineNumberAffected'
						id='lineNumberAffected'
						value={lineNumberAffected || ''}
						onBlur={(event) => onBlur(event)}
						onChange={(event) => handleInputChange(event)}
					/>
				</Column>
			</Row>
			{requestType === 'PartsOnly' &&
				<Row>
					<Column xs={12} sm={6}><label htmlFor='issueDesc' className='required'>Description of Issue:<span style={{ color: '#a70215' }}>*</span></label></Column>
					<Column xs={12} sm={6}>
						<TextArea
							name='issueDesc'
							id='issueDesc'
							value={issueDesc || ''}
							onBlur={(event) => onBlur(event)}
							onChange={(event) => handleInputChange(event)}
						/>
					</Column>
				</Row>
			}
			{/* <Row>
				<Column xs={12} sm={6}><label htmlFor='bestTextNumber'>Best Text Number:</label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='bestTextNumber'
						value={bestTextNumber || ''}
						onChange={(event) => handleInputChange(event)}
					/>
				</Column>
			</Row> */}
			{(requestType === 'PartsOnly' || requestType === 'PartsWithService') &&
			<>
				<Row>
					<Column xs={12} sm={6}><label htmlFor='deliverParts' className='required'>Deliver Parts to:<span style={{ color: '#a70215' }}>*</span></label></Column>
					<Column xs={12} sm={6}>
						<Checkbox
							type='radio'
							id='deliverParts'
							name='deliverParts'
							labelText='Store'
							value='Store'
							group='partsDeliver'
							checked={deliverParts === 'Store' ? true : false}
							onChange={(event) => handleInputChange(event)}
							onBlur={(event) => onBlur(event)}
						/>
						<Checkbox
							type='radio'
							id='deliverPartsOther'
							name='deliverParts'
							labelText='Other Location'
							value='Other Location'
							group='partsDeliver'
							checked={deliverParts === 'Other Location' ? true : false}
							onChange={(event) => handleInputChange(event)}
							onBlur={(event) => onBlur(event)}
						/>						
						{errors.deliverParts &&
							<Row><div className='error-text mt-15'>{errors.deliverParts}</div></Row>
						}
					</Column>
				</Row>

			</>
			}
			{deliverParts === 'Other Location' && (requestType === 'PartsOnly' || requestType === 'PartsWithService') &&
			<>
				<Row className='mt-15'>
					<Column xs={12} sm={6}><label htmlFor='shippingName' className='required'>Shipping Name:<span style={{ color: '#a70215' }}>*</span></label></Column>
					<Column xs={12} sm={6}>
						<TextInput
							name='shippingName'
							id='shippingName'
							value={shippingName || ''}
							onChange={(event) => handleInputChange(event)}
							onBlur={(event) => onBlur(event)}
							type='text'
							aria-required='true'
							autoComplete='off'
							error={errors.shippingName ? true : false}
							errorText={errors.shippingName}
						/></Column>
				</Row>
				<Row>
				<Column xs={12} sm={6}><label htmlFor='shippingAddress' className='required'>Shipping Address:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>					
					<TextInput 
						name='shippingAddress'
						id='shippingAddress'
						value={shippingAddress || ''}
						onChange={(event) => handleInputChange(event)}
						onBlur={(event) => onBlur(event)}
						type='text'
						aria-required='true'
						autoComplete='off'
						error={errors.shippingAddress ? true : false}
						errorText={errors.shippingAddress}
					/></Column>
				</Row>
				<Row>
					<Column xs={12} sm={6}><label htmlFor='shippingCity' className='required'>Shipping City:<span style={{ color: '#a70215' }}>*</span></label></Column>
					<Column xs={12} sm={6}>
						<TextInput
							name='shippingCity'
							id='shippingCity'
							value={shippingCity || ''}
							onChange={(event) => handleInputChange(event)}
							onBlur={(event) => onBlur(event)}
							type='text'
							aria-required='true'
							autoComplete='off'
							error={errors.shippingCity ? true : false}
							errorText={errors.shippingCity}
						/></Column>
				</Row>
				<Row>
					<Column xs={12} sm={6}><label htmlFor='shippingState' className='required'>Shipping State:<span style={{ color: '#a70215' }}>*</span></label></Column>
					<Column xs={12} sm={6}>
						<Select
							id='shippingState'
							name='shippingState'
							value={shippingState || ''}
							onChange={(event) => handleInputChange(event)}
							onBlur={(event) => onBlur(event)}
							aria-required='true'
							autoComplete='off'
							error={errors.shippingState ? true : false}
							errorText={errors.shippingState}
						>
							<option value=''>Select...</option>
							<optgroup label='United States'>
								<option id='USA-AL' value='AL'>Alabama (AL)</option>
								<option id='USA-AK' value='AK'>Alaska (AK)</option>
								<option id='USA-AZ' value='AZ'>Arizona (AZ)</option>
								<option id='USA-AR' value='AR'>Arkansas (AR)</option>
								<option id='USA-CA' value='CA'>California (CA)</option>
								<option id='USA-CO' value='CO'>Colorado (CO)</option>
								<option id='USA-CT' value='CT'>Connecticut (CT)</option>
								<option id='USA-DE' value='DE'>Delaware (DE)</option>
								<option id='USA-DC' value='DC'>District of Columbia (DC)</option>
								<option id='USA-FL' value='FL'>Florida (FL)</option>
								<option id='USA-GA' value='GA'>Georgia (GA)</option>
								<option id='USA-GU' value='GU'>Guam (GU)</option>
								<option id='USA-HI' value='HI'>Hawaii (HI)</option>
								<option id='USA-ID' value='ID'>Idaho (ID)</option>
								<option id='USA-IL' value='IL'>Illinois (IL)</option>
								<option id='USA-IN' value='IN'>Indiana (IN)</option>
								<option id='USA-IA' value='IA'>Iowa (IA)</option>
								<option id='USA-KS' value='KS'>Kansas (KS)</option>
								<option id='USA-KY' value='KY'>Kentucky (KY)</option>
								<option id='USA-LA' value='LA'>Louisiana (LA)</option>
								<option id='USA-ME' value='ME'>Maine (ME)</option>
								<option id='USA-MD' value='MD'>Maryland (MD)</option>
								<option id='USA-MA' value='MA'>Massachusetts (MA)</option>
								<option id='USA-MI' value='MI'>Michigan (MI)</option>
								<option id='USA-MN' value='MN'>Minnesota (MN)</option>
								<option id='USA-MS' value='MS'>Mississippi (MS)</option>
								<option id='USA-MO' value='MO'>Missouri (MO)</option>
								<option id='USA-MT' value='MT'>Montana (MT)</option>
								<option id='USA-NE' value='NE'>Nebraska (NE)</option>
								<option id='USA-NV' value='NV'>Nevada (NV)</option>
								<option id='USA-NH' value='NH'>New Hampshire (NH)</option>
								<option id='USA-NJ' value='NJ'>New Jersey (NJ)</option>
								<option id='USA-NM' value='NM'>New Mexico (NM)</option>
								<option id='USA-NY' value='NY'>New York (NY)</option>
								<option id='USA-NC' value='NC'>North Carolina (NC)</option>
								<option id='USA-ND' value='ND'>North Dakota (ND)</option>
								<option id='USA-OH' value='OH'>Ohio (OH)</option>
								<option id='USA-OK' value='OK'>Oklahoma (OK)</option>
								<option id='USA-OR' value='OR'>Oregon (OR)</option>
								<option id='USA-PA' value='PA'>Pennsylvania (PA)</option>
								<option id='USA-PR' value='PR'>Puerto Rico (PR)</option>
								<option id='USA-RI' value='RI'>Rhode Island (RI)</option>
								<option id='USA-SC' value='SC'>South Carolina (SC)</option>
								<option id='USA-SD' value='SD'>South Dakota (SD)</option>
								<option id='USA-TN' value='TN'>Tennessee (TN)</option>
								<option id='USA-TX' value='TX'>Texas (TX)</option>
								<option id='USA-UT' value='UT'>Utah (UT)</option>
								<option id='USA-VT' value='VT'>Vermont (VT)</option>
								<option id='USA-VA' value='VA'>Virginia (VA)</option>
								<option id='USA-VI' value='VI'>Virgin Islands (VI)</option>
								<option id='USA-WA' value='WA'>Washington (WA)</option>
								<option id='USA-WV' value='WV'>West Virginia (WV)</option>
								<option id='USA-WI' value='WI'>Wisconsin (WI)</option>
								<option id='USA-WY' value='WY'>Wyoming (WY)</option>
							</optgroup>
							<optgroup label='Canada'>
								<option id='CAN-AB' value='AB'>Alberta (AB)</option>
								<option id='CAN-BC' value='BC'>British Columbia (BC)</option>
								<option id='CAN-MB' value='MB'>Manitoba (MB)</option>
								<option id='CAN-NB' value='NB'>New Brunswick (NB)</option>
								<option id='CAN-NL' value='NL'>Newfoundland and Labrador (NL)</option>
								<option id='CAN-NT' value='NT'>Northwest Territories (NT)</option>
								<option id='CAN-NS' value='NS'>Nova Scotia (NS)</option>
								<option id='CAN-NU' value='NU'>Nunavut (NU)</option>
								<option id='CAN-PE' value='PE'>Prince Edward Island (PE)</option>
								<option id='CAN-SK' value='SK'>Saskatchewan (SK)</option>
								<option id='CAN-ON' value='ON'>Ontario (ON)</option>
								<option id='CAN-QC' value='QC'>Quebec (QC)</option>
								<option id='CAN-YT' value='YT'>Yukon (YT)</option>
							</optgroup>
						</Select>
					</Column>
				</Row>
				<Row>
					<Column xs={12} sm={6}><label htmlFor='shippingZip' className='required'>Shipping Zip:<span style={{ color: '#a70215' }}>*</span></label></Column>
					<Column xs={12} sm={6}>
						<TextInput
							name='shippingZip'
							id='shippingZip'
							value={shippingZip || ''}
							onChange={(event) => handleInputChange(event)}
							onBlur={(event) => onBlur(event)}
							type='text'
							aria-required='true'
							autoComplete='off'
							error={errors.shippingZip ? true : false}
							errorText={errors.shippingZip}
						/></Column>
				</Row>
				<Row>
					<Column xs={12} sm={6}><label htmlFor='shippingPhone'>Shipping Phone:</label></Column>
					<Column xs={12} sm={6}>
						<TextInput
							name='shippingPhone'
							id='shippingPhone'
							value={shippingPhone || ''}
							onChange={(event) => handleInputChange(event)}
							onBlur={(event) => onBlur(event)}
							type='text'
							aria-required='true'
							autoComplete='off'
							error={errors.shippingPhone ? true : false}
							errorText={errors.shippingPhone}
						/></Column>
				</Row>
			</>
			}
			{ !expanded &&
				<Row className='sf-justify-content--end'>
					<Button color='primary' type='button' onClick={() => handleViewSection('JobInfo')} >Next</Button>
				</Row>
			}
		</Container>
	);
};

export default SwatStoreForm;
