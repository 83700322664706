import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { graphql } from 'gatsby';
import sessionStorage from "sessionstorage";
import { Container, Row, Column, Button, TextArea, Checkbox, Select, TextInput, Alert, List } from 'sash-foundation';
import { Layout, BaseLink, BaseImage, BaseButton } from '../../components';
import EloquaForm from '../../components/common/eloqua-form';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';

import Hero from '../../components/common/hero';
import Feature from '../../components/common/feature';
import Video from '../../components/common/video';

import './index.scss';

const General = (props) => {
	const pageData = props.data.contentstackNewGeneral;
	const [update, setUpdate] = useState(false);
	const [success, setSuccess] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [values, setValues] = useState({
		singleLineText: '',
		singleLineText2: '',
		singleLineText3: '',
		singleLineText4: '',
		singleLineText5: '',
		singleLineText6: '',
		singleLineText7: '',
		singleLineText8: '',
		singleLineText9: '',
		singleLineText10: '',
		parts: [],
	});
	const [errors, setErrors] = useState([]);
	const [partsArray, setPartsArray] = useState([
		{
			key: 0,
			project1ordernumber: '',
			project1imperviasales: '',
			project1buildercontractorname: '',
		},
	]);
	const [userCompany, setUserCompany] = useState(sessionStorage.getItem('userCompany') || "");
	const [selectedCompany, setSelectedCompany] = useState(sessionStorage.getItem('selectedCompany') || "");
	const [prevUserCompany] = useState(sessionStorage.getItem('userCompany') || "");
	const [prevSelectedCompany] = useState(sessionStorage.getItem('selectedCompany') || "");
	const [promoVisible, setPromoVisible] = useState([]);

	const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const zipcodeRegex = /^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/;

	useEffect(() => {
		if (update) {
			setUpdate(false);
		}

	}, [update]);

	useEffect(() => {
		if (userCompany !== prevUserCompany || prevSelectedCompany !== selectedCompany) {
			window.location.reload(true);
		}
	}, [userCompany, selectedCompany, prevSelectedCompany, prevUserCompany]);

	useEffect(() => {
		pageData.modular_blocks.map((block, index) => {
			if (block.general_content != null && block.general_content?.visibility_role) {
				promoVisible.push(block.general_content.visibility_role);
			}
		});
		setUpdate(true);
	}, [pageData]);

	const onInputChange = (e) => {
		setValues({
			...values,
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
		setSuccess(false);
		setSubmitDisabled(false);
	};

	const handleProjectChange = (index) => (event) => {
		let newArray = [...partsArray];
		newArray[index][event.target.name] = event.target.value;
		setPartsArray(newArray);
		setSuccess(false);
		setSubmitDisabled(false);
		setUpdate(true);
	};

	const handleAddProjectItem = (length) => {
		let newArray;
		switch (length) {
			case 1:
				newArray = {
					key: partsArray.length,
					project2ordernumber: '',
					project2imperviasales: '',
					project2buildercontractorname: '',
				};
				break;
			case 2:
				newArray = {
					key: partsArray.length,
					project3ordernumber: '',
					project3imperviasales: '',
					project3buildercontractorname: '',
				};
				break;
			case 3:
				newArray = {
					key: partsArray.length,
					project4ordernumber: '',
					project4imperviasales: '',
					project4buildercontractorname: '',
				};
				break;
			case 4:
				newArray = {
					key: partsArray.length,
					project5ordernumber: '',
					project5imperviasales: '',
					project5buildercontractorname: '',
				};
				break;
			default:
				break;
		}
		//let newArray = { key: partsArray.length };
		setPartsArray((partsArray) => [...partsArray, newArray]);
		setUpdate(true);
	};

	const handleRemoveProjectItem = () => {
		let newArray = [...partsArray];

		newArray.pop();

		setPartsArray(newArray);
		setValues({ ...values, parts: [newArray] });
		setUpdate(true);
	};

	const finalValidation = () => {
		let totalErrors = [];

		if (values.singleLineText.length === 0) {
			totalErrors.push({ singleLineText: 'First Name is required' });
		}
		if (values.singleLineText2.length === 0) {
			totalErrors.push({ singleLineText2: 'Last Name is required' });
		}
		if (values.singleLineText3.length === 0) {
			totalErrors.push({ singleLineText3: 'Store Number is required' });
		}
		if (values.singleLineText4.length === 0) {
			totalErrors.push({ singleLineText4: 'Store City is required' });
		}
		if (values.singleLineText5.length === 0) {
			totalErrors.push({ singleLineText5: 'Email is required' });
		}
		if (values.singleLineText5.match(emailRegex) === null && values.singleLineText5.length !== 0) {
			totalErrors.push({
				singleLineText5: 'Please enter a valid Email Address',
			});
		}
		if (values.singleLineText6.length === 0) {
			totalErrors.push({ singleLineText6: 'Store State is required' });
		}
		if (values.singleLineText7.length === 0) {
			totalErrors.push({ singleLineText7: 'Address is required' });
		}
		if (values.singleLineText8.length === 0) {
			totalErrors.push({ singleLineText8: 'City is required' });
		}
		if (values.singleLineText9.length === 0) {
			totalErrors.push({ singleLineText9: 'State is required' });
		}
		if (values.singleLineText10.length === 0) {
			totalErrors.push({ singleLineText10: 'Zipcode is required' });
		}
		if (values.singleLineText10.length !== 0 && values.singleLineText10.match(zipcodeRegex) === null) {
			totalErrors.push({ singleLineText10: 'Zipcode is invalid' });
		}
		if (partsArray[0]?.project1ordernumber?.length === 0) {
			totalErrors.push({
				project1ordernumber: 'Project Order Number is Required',
			});
		}
		if (partsArray[0]?.project1imperviasales?.length === 0) {
			totalErrors.push({
				project1imperviasales: 'Project Impervia Cost is Required',
			});
		}
		if (partsArray[0]?.project1buildercontractorname?.length === 0) {
			totalErrors.push({
				project1buildercontractorname: 'Project Builder/Contractor Name is Required',
			});
		}
		if (partsArray[1]?.project2ordernumber?.length === 0) {
			totalErrors.push({
				project2ordernumber: 'Project Order Number is Required',
			});
		}
		if (partsArray[1]?.project2imperviasales?.length === 0) {
			totalErrors.push({
				project2imperviasales: 'Project Impervia Cost is Required',
			});
		}
		if (partsArray[1]?.project2buildercontractorname?.length === 0) {
			totalErrors.push({
				project2buildercontractorname: 'Project Builder/Contractor Name is Required',
			});
		}
		if (partsArray[2]?.project3ordernumber?.length === 0) {
			totalErrors.push({
				project3ordernumber: 'Project Order Number is Required',
			});
		}
		if (partsArray[2]?.project3imperviasales?.length === 0) {
			totalErrors.push({
				project3imperviasales: 'Project Impervia Cost is Required',
			});
		}
		if (partsArray[2]?.project3buildercontractorname?.length === 0) {
			totalErrors.push({
				project3buildercontractorname: 'Project Builder/Contractor Name is Required',
			});
		}
		if (partsArray[3]?.project4ordernumber?.length === 0) {
			totalErrors.push({
				project4ordernumber: 'Project Order Number is Required',
			});
		}
		if (partsArray[3]?.project4imperviasales?.length === 0) {
			totalErrors.push({
				project4imperviasales: 'Project Impervia Cost is Required',
			});
		}
		if (partsArray[3]?.project4buildercontractorname?.length === 0) {
			totalErrors.push({
				project4buildercontractorname: 'Project Builder/Contractor Name is Required',
			});
		}
		if (partsArray[4]?.project5ordernumber?.length === 0) {
			totalErrors.push({
				project5ordernumber: 'Project Order Number is Required',
			});
		}
		if (partsArray[4]?.project5imperviasales?.length === 0) {
			totalErrors.push({
				project5imperviasales: 'Project Impervia Cost is Required',
			});
		}
		if (partsArray[4]?.project5buildercontractorname?.length === 0) {
			totalErrors.push({
				project5buildercontractorname: 'Project Builder/Contractor Name is Required',
			});
		}

		if (totalErrors.length !== 0) {
			let finalErrors = Object.assign({}, ...totalErrors);
			return finalErrors;
		} else {
			return true;
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();

		if (finalValidation() == true) {
			const data = new FormData(e.currentTarget);
			axios
				.post('https://tracking.pella.com/e/f2', data)
				.then(() => {
					setSuccess(true);
					setSubmitDisabled(true);
					setValues({
						singleLineText: '',
						singleLineText2: '',
						singleLineText3: '',
						singleLineText4: '',
						singleLineText5: '',
						singleLineText6: '',
						singleLineText7: '',
						singleLineText8: '',
						singleLineText9: '',
						singleLineText10: '',
						parts: [],
					});
					setPartsArray([
						{
							key: 0,
							project1ordernumber: '',
							project1imperviasales: '',
							project1buildercontractorname: '',
						},
					]);
					setUpdate(true);
				})
				.catch
				// err => console.log(err)
				();
			setUpdate(true);
		} else {
			let finalError = finalValidation();
			setSubmitDisabled(true);
			setErrors(finalError);
		}
	};

	return (
		<Layout>
			{/* <SEO {...pageData.seo} /> */}

			{pageData?.hero?.image.length > 0 && <Hero data={pageData.hero} className='coop-general-Hero' />}

			{pageData.top_content && (
				<div className='coop-general-TopContent'>
					<Container width={1530}>
						{pageData.top_content.heading && <h1>{pageData.top_content.heading}</h1>}
						{pageData.top_content.description && (
							<div
								dangerouslySetInnerHTML={{
									__html: pageData.top_content.description,
								}}
							/>
						)}
					</Container>
				</div>
			)}

			{pageData.modular_blocks?.[0] &&
				pageData.modular_blocks.map((block, index) => {
					if (block.feature != null) {
						return (
							<Container width={1530} key={index}>
								<Feature data={block.feature} contain={true} />
							</Container>
						);
					}

					if (block.general_content != null) {
						if (block.general_content?.visibility_role?.length > 0) {
							if (promoVisible[0] && promoVisible[0].includes(selectedCompany)) {
								return (
									<div key={index} className='coop-general-Content sf-text--center sf-my--lg sf-my-1030--xxl'>
										<Container width={1530}>
											{block.general_content.heading && <h3>{block.general_content.heading}</h3>}
											{block.general_content.description && (
												<div
													dangerouslySetInnerHTML={{
														__html: block.general_content.description,
													}}
												/>
											)}
											{block.general_content?.cta?.[0] &&
												<BaseButton data={block.general_content.cta[0]} />
											}
										</Container>
									</div>
								);
							}
							else if (promoVisible[0] && promoVisible[0].includes(userCompany) && selectedCompany === '') {
								return (
									<div key={index} className='coop-general-Content sf-text--center sf-my--lg sf-my-1030--xxl'>
										<Container width={1530}>
											{block.general_content.heading && <h3>{block.general_content.heading}</h3>}
											{block.general_content.description && (
												<div
													dangerouslySetInnerHTML={{
														__html: block.general_content.description,
													}}
												/>
											)}
											{block.general_content?.cta?.[0] &&
												<BaseButton data={block.general_content.cta[0]} />
											}
										</Container>
									</div>
								);
							}
						}
						else {
							return (
								<div key={index} className='coop-general-Content sf-text--center sf-my--lg sf-my-1030--xxl'>
									<Container width={1530}>
										{block.general_content.heading && <h2>{block.general_content.heading}</h2>}
										{block.general_content.description && (
											<div
												dangerouslySetInnerHTML={{
													__html: block.general_content.description,
												}}
											/>
										)}
									</Container>
								</div>
							);
						}
					}

					if (block.video_grid?.group?.[0]) {
						return (
							<Container width={1530} key={index}>
								<div className='coop-general-VideoGrid sf-py--lg sf-py-1030--xxl'>
									<Row>
										{block.video_grid.group.map((item, index) => (
											<Column className='coop-video' xs={12} lg='fluid' key={index}>
												<div className='coop-flex'>
													{item.video?.[0] && <Video src={item.video[0].video_link?.href} imageSrc={item.video[0].image_overlay?.[0]} overlay={item.video[0].image_overlay?.[0] ? true : false} />}

													{item.internal_video && <Video src={item.internal_video.url} video={item.internal_video} youtube={false} overflay={false} />}

													{item.video_title && <h2 className='sf-text--center sf-mb--md coop-marginTop'>{item.video_title}</h2>}

													{item.description && (
														<div
															dangerouslySetInnerHTML={{
																__html: item.description,
															}}
															className='sf-mb--lg sf-text--center coop-marginTopA'
														/>
													)}
												</div>
											</Column>
										))}
									</Row>
								</div>
							</Container>
						);
					}

					if (block.content_group?.content_item?.[0]) {
						return (
							<div key={index} className='coop-general-IWC sf-text--center sf-pb--lg sf-pb-1030--xxl'>
								<Container width={1530}>
									<Row justify='center' verticalGutters={true} className='sf-mt--lg'>
										{block.content_group.content_item.map((iwc, index) => (
											<Column key={index} xs={12} sm={6} lg={4}>
												{(iwc.cta?.[0] && (
													<BaseLink data={iwc.cta[0]}>
														{iwc.image?.[0] && <BaseImage data={iwc.image[0]} />}
														{iwc.heading && <p className='coop-iwc-heading'>{iwc.heading}</p>}
														{iwc.subheading && <p className='coop-iwc-subheading'>{iwc.subheadaing}</p>}
													</BaseLink>
												)) || (
														<>
															{iwc.image?.[0] && <BaseImage data={iwc.image[0]} />}
															{iwc.heading && <p className='coop-iwc-heading sf-mt--lg sf-mb--0'>{iwc.heading}</p>}
															{iwc.subtitle && <p className='coop-iwc-subtitle sf-my--0'>{iwc.subtitle}</p>}
														</>
													)}
											</Column>
										))}
									</Row>

									{block.content_group.cta?.[0] && (
										<div className='sf-my--xl sf-text--center'>
											<List bullets='none' layout='inline' className='sf-justify-content--center'>
												{block.content_group.cta.map((cta, index) => (
													<li key={index} className='sf-m--sm'>
														<BaseButton data={cta} />
													</li>
												))}
											</List>
										</div>
									)}
								</Container>
							</div>
						);
					}

					if (block.form != null && block.form.form_type === 'incentive') {
						return (
							<div key={index}>
								<Container width={1530}>
									<EloquaForm
										elqFormName='ProDealerSalesIncentive'
										onFormSubmit={(e) => onSubmit(e)}
									// className={styles.designworksForm}
									>
										<Row className='sf-mt--lg'>
											<Column xs={12} sm={4}>
												<TextInput id='singleLineText' labelText='OSR First Name' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText} error={errors.singleLineText ? true : false} errorText={errors.singleLineText} autoComplete='off' />
											</Column>
											<Column xs={12} sm={4}>
												<TextInput id='singleLineText2' labelText='OSR Last Name' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText2} error={errors.singleLineText2 ? true : false} errorText={errors.singleLineTex2} autoComplete='off' />
											</Column>
											<Column xs={12} sm={4}>
												<TextInput id='singleLineText5' labelText='Email Address' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText5} error={errors.singleLineText5 ? true : false} errorText={errors.singleLineText5} autoComplete='off' />
											</Column>
											<Column xs={12} sm={4}>
												<TextInput id='singleLineText3' labelText='Store Number' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText3} error={errors.singleLineText3 ? true : false} errorText={errors.singleLineText3} autoComplete='off' />
											</Column>
											<Column xs={12} sm={4}>
												<TextInput id='singleLineText4' labelText='Store City' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText4} error={errors.singleLineText4 ? true : false} errorText={errors.singleLineText4} autoComplete='off' />
											</Column>
											<Column xs={12} sm={4}>
												<Select id='singleLineText6' name='singleLineText6' labelText='Store State' value={values.singleLineText6 || ''} onChange={(e) => onInputChange(e)} aria-required='true' required={true} autoComplete='off' error={errors.singleLineText6 ? true : false} errorText={errors.singleLineText6}>
													<option value=''>Select...</option>
													<optgroup label='United States'>
														<option id='USA-AL' value='AL'>
															Alabama (AL)
														</option>
														<option id='USA-AK' value='AK'>
															Alaska (AK)
														</option>
														<option id='USA-AZ' value='AZ'>
															Arizona (AZ)
														</option>
														<option id='USA-AR' value='AR'>
															Arkansas (AR)
														</option>
														<option id='USA-CA' value='CA'>
															California (CA)
														</option>
														<option id='USA-CO' value='CO'>
															Colorado (CO)
														</option>
														<option id='USA-CT' value='CT'>
															Connecticut (CT)
														</option>
														<option id='USA-DE' value='DE'>
															Delaware (DE)
														</option>
														<option id='USA-DC' value='DC'>
															District of Columbia (DC)
														</option>
														<option id='USA-FL' value='FL'>
															Florida (FL)
														</option>
														<option id='USA-GA' value='GA'>
															Georgia (GA)
														</option>
														<option id='USA-GU' value='GU'>
															Guam (GU)
														</option>
														<option id='USA-HI' value='HI'>
															Hawaii (HI)
														</option>
														<option id='USA-ID' value='ID'>
															Idaho (ID)
														</option>
														<option id='USA-IL' value='IL'>
															Illinois (IL)
														</option>
														<option id='USA-IN' value='IN'>
															Indiana (IN)
														</option>
														<option id='USA-IA' value='IA'>
															Iowa (IA)
														</option>
														<option id='USA-KS' value='KS'>
															Kansas (KS)
														</option>
														<option id='USA-KY' value='KY'>
															Kentucky (KY)
														</option>
														<option id='USA-LA' value='LA'>
															Louisiana (LA)
														</option>
														<option id='USA-ME' value='ME'>
															Maine (ME)
														</option>
														<option id='USA-MD' value='MD'>
															Maryland (MD)
														</option>
														<option id='USA-MA' value='MA'>
															Massachusetts (MA)
														</option>
														<option id='USA-MI' value='MI'>
															Michigan (MI)
														</option>
														<option id='USA-MN' value='MN'>
															Minnesota (MN)
														</option>
														<option id='USA-MS' value='MS'>
															Mississippi (MS)
														</option>
														<option id='USA-MO' value='MO'>
															Missouri (MO)
														</option>
														<option id='USA-MT' value='MT'>
															Montana (MT)
														</option>
														<option id='USA-NE' value='NE'>
															Nebraska (NE)
														</option>
														<option id='USA-NV' value='NV'>
															Nevada (NV)
														</option>
														<option id='USA-NH' value='NH'>
															New Hampshire (NH)
														</option>
														<option id='USA-NJ' value='NJ'>
															New Jersey (NJ)
														</option>
														<option id='USA-NM' value='NM'>
															New Mexico (NM)
														</option>
														<option id='USA-NY' value='NY'>
															New York (NY)
														</option>
														<option id='USA-NC' value='NC'>
															North Carolina (NC)
														</option>
														<option id='USA-ND' value='ND'>
															North Dakota (ND)
														</option>
														<option id='USA-OH' value='OH'>
															Ohio (OH)
														</option>
														<option id='USA-OK' value='OK'>
															Oklahoma (OK)
														</option>
														<option id='USA-OR' value='OR'>
															Oregon (OR)
														</option>
														<option id='USA-PA' value='PA'>
															Pennsylvania (PA)
														</option>
														<option id='USA-PR' value='PR'>
															Puerto Rico (PR)
														</option>
														<option id='USA-RI' value='RI'>
															Rhode Island (RI)
														</option>
														<option id='USA-SC' value='SC'>
															South Carolina (SC)
														</option>
														<option id='USA-SD' value='SD'>
															South Dakota (SD)
														</option>
														<option id='USA-TN' value='TN'>
															Tennessee (TN)
														</option>
														<option id='USA-TX' value='TX'>
															Texas (TX)
														</option>
														<option id='USA-UT' value='UT'>
															Utah (UT)
														</option>
														<option id='USA-VT' value='VT'>
															Vermont (VT)
														</option>
														<option id='USA-VA' value='VA'>
															Virginia (VA)
														</option>
														<option id='USA-VI' value='VI'>
															Virgin Islands (VI)
														</option>
														<option id='USA-WA' value='WA'>
															Washington (WA)
														</option>
														<option id='USA-WV' value='WV'>
															West Virginia (WV)
														</option>
														<option id='USA-WI' value='WI'>
															Wisconsin (WI)
														</option>
														<option id='USA-WY' value='WY'>
															Wyoming (WY)
														</option>
													</optgroup>
													<optgroup label='Canada'>
														<option id='CAN-AB' value='AB'>
															Alberta (AB)
														</option>
														<option id='CAN-BC' value='BC'>
															British Columbia (BC)
														</option>
														<option id='CAN-MB' value='MB'>
															Manitoba (MB)
														</option>
														<option id='CAN-NB' value='NB'>
															New Brunswick (NB)
														</option>
														<option id='CAN-NL' value='NL'>
															Newfoundland and Labrador (NL)
														</option>
														<option id='CAN-NT' value='NT'>
															Northwest Territories (NT)
														</option>
														<option id='CAN-NS' value='NS'>
															Nova Scotia (NS)
														</option>
														<option id='CAN-NU' value='NU'>
															Nunavut (NU)
														</option>
														<option id='CAN-PE' value='PE'>
															Prince Edward Island (PE)
														</option>
														<option id='CAN-SK' value='SK'>
															Saskatchewan (SK)
														</option>
														<option id='CAN-ON' value='ON'>
															Ontario (ON)
														</option>
														<option id='CAN-QC' value='QC'>
															Quebec (QC)
														</option>
														<option id='CAN-YT' value='YT'>
															Yukon (YT)
														</option>
													</optgroup>
												</Select>
											</Column>
											<Column xs={12} sm={12}>
												<TextInput id='singleLineText7' labelText='Address where gift card should be mailed' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText7} error={errors.singleLineText7 ? true : false} errorText={errors.singleLineText7} autoComplete='off' />
											</Column>
											<Column xs={12} sm={4}>
												<TextInput id='singleLineText8' labelText='City' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText8} error={errors.singleLineText8 ? true : false} errorText={errors.singleLineText8} autoComplete='off' />
											</Column>
											<Column xs={12} sm={4}>
												<TextInput id='singleLineText9' labelText='State' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText9} error={errors.singleLineText9 ? true : false} errorText={errors.singleLineText9} autoComplete='off' />
											</Column>
											<Column xs={12} sm={4}>
												<TextInput id='singleLineText10' labelText='Zip' required={true} onChange={(e) => onInputChange(e)} value={values.singleLineText10} error={errors.singleLineText10 ? true : false} errorText={errors.singleLineText10} autoComplete='off' />
											</Column>
										</Row>
										<div className='coop-projectHeading'>
											<p>Project Information</p>
										</div>

										{partsArray?.map((part, index) => (
											<div key={index} className='sf-mb--lg'>
												<div className='coop-card'>
													{index === 0 && (
														<>
															<div className='coop-projectSubHeading'>
																<p>Project #1</p>
															</div>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={`project1ordernumber`}> Project PO Number</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project1ordernumber'} name={'project1ordernumber'} value={part.project1ordernumber} onChange={handleProjectChange(index)} error={errors.project1ordernumber ? true : false} errorText={errors.project1ordernumber} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project1imperviasales'}>Project Impervia Cost (Invoiced Amount)</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project1imperviasales'} name={'project1imperviasales'} value={part.project1imperviasales} onChange={handleProjectChange(index)} error={errors.project1imperviasales ? true : false} errorText={errors.project1imperviasales} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project1buildercontractorname'}>Project Builder/Contractor Name</label>
																</Column>

																<Column xs={12} md={6}>
																	<TextInput id={'project1buildercontractorname'} name={'project1buildercontractorname'} value={part.project1buildercontractorname} onChange={handleProjectChange(index)} error={errors.project1buildercontractorname ? true : false} errorText={errors.project1buildercontractorname} hideLabel />
																</Column>
															</Row>
														</>
													)}
													{index === 1 && (
														<>
															<div className='coop-projectSubHeading'>
																<p>Project #2</p>
															</div>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={`project2ordernumber`}> Project PO Number</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project2ordernumber'} name={'project2ordernumber'} value={part.project2ordernumber} onChange={handleProjectChange(index)} error={errors.project2ordernumber ? true : false} errorText={errors.project2ordernumber} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project2imperviasales'}>Project Impervia Cost (Invoiced Amount)</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project2imperviasales'} name={'project2imperviasales'} value={part.project2imperviasales} onChange={handleProjectChange(index)} error={errors.project2imperviasales ? true : false} errorText={errors.project2imperviasales} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project2buildercontractorname'}>Project Builder/Contractor Name</label>
																</Column>

																<Column xs={12} md={6}>
																	<TextInput id={'project2buildercontractorname'} name={'project2buildercontractorname'} value={part.project2buildercontractorname} onChange={handleProjectChange(index)} error={errors.project2buildercontractorname ? true : false} errorText={errors.project2buildercontractorname} hideLabel />
																</Column>
															</Row>
														</>
													)}
													{index === 2 && (
														<>
															<div className='coop-projectSubHeading'>
																<p>Project #3</p>
															</div>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={`project3ordernumber`}> Project PO Number</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project3ordernumber'} name={'project3ordernumber'} value={part.project3ordernumber} onChange={handleProjectChange(index)} error={errors.project3ordernumber ? true : false} errorText={errors.project3ordernumber} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project3imperviasales'}>Project Impervia Cost (Invoiced Amount)</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project3imperviasales'} name={'project3imperviasales'} value={part.project3imperviasales} onChange={handleProjectChange(index)} error={errors.project3imperviasales ? true : false} errorText={errors.project3imperviasales} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project3buildercontractorname'}>Project Builder/Contractor Name</label>
																</Column>

																<Column xs={12} md={6}>
																	<TextInput id={'project3buildercontractorname'} name={'project3buildercontractorname'} value={part.project3buildercontractorname} onChange={handleProjectChange(index)} error={errors.project3buildercontractorname ? true : false} errorText={errors.project3buildercontractorname} hideLabel />
																</Column>
															</Row>
														</>
													)}
													{index === 3 && (
														<>
															<div className='coop-projectSubHeading'>
																<p>Project #4</p>
															</div>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={`project4ordernumber`}> Project PO Number</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project4ordernumber'} name={'project4ordernumber'} value={part.project4ordernumber} onChange={handleProjectChange(index)} error={errors.project4ordernumber ? true : false} errorText={errors.project4ordernumber} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project4imperviasales'}>Project Impervia Cost (Invoiced Amount)</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project4imperviasales'} name={'project4imperviasales'} value={part.project4imperviasales} onChange={handleProjectChange(index)} error={errors.project4imperviasales ? true : false} errorText={errors.project4imperviasales} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project4buildercontractorname'}>Project Builder/Contractor Name</label>
																</Column>

																<Column xs={12} md={6}>
																	<TextInput id={'project4buildercontractorname'} name={'project4buildercontractorname'} value={part.project4buildercontractorname} onChange={handleProjectChange(index)} error={errors.project4buildercontractorname ? true : false} errorText={errors.project4buildercontractorname} hideLabel />
																</Column>
															</Row>
														</>
													)}
													{index === 4 && (
														<>
															<div className='coop-projectSubHeading'>
																<p>Project #5</p>
															</div>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={`project5ordernumber`}> Project PO Number</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project5ordernumber'} name={'project5ordernumber'} value={part.project5ordernumber} onChange={handleProjectChange(index)} error={errors.project5ordernumber ? true : false} errorText={errors.project5ordernumber} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project5imperviasales'}>Project Impervia Cost (Invoiced Amount)</label>
																</Column>
																<Column xs={12} md={6}>
																	<TextInput id={'project5imperviasales'} name={'project5imperviasales'} value={part.project5imperviasales} onChange={handleProjectChange(index)} error={errors.project5imperviasales ? true : false} errorText={errors.project5imperviasales} hideLabel />
																</Column>
															</Row>
															<Row>
																<Column xs={12} md={4}>
																	<label htmlFor={'project5buildercontractorname'}>Project Builder/Contractor Name</label>
																</Column>

																<Column xs={12} md={6}>
																	<TextInput id={'project5buildercontractorname'} name={'project5buildercontractorname'} value={part.project5buildercontractorname} onChange={handleProjectChange(index)} error={errors.project5buildercontractorname ? true : false} errorText={errors.project5buildercontractorname} hideLabel />
																</Column>
															</Row>
														</>
													)}
													{/* {partsArray.length > 1  &&
													<Row className='sf-justify-content--end sf-pr--md'>
														<div className='coop-add-remove-button' tabIndex='0' role='button' onClick={() => handleRemoveProjectItem(index) }><span><AiFillMinusCircle /> </span><span>Remove Project</span></div>
													</Row>
												} */}
												</div>
											</div>
										))}
										<Row className='sf-pr--md'>
											{partsArray.length < 5 && (
												<Column xs={3}>
													<div className='coop-add-remove-button' role='button' tabIndex='0' onClick={() => handleAddProjectItem(partsArray.length)}>
														<span>
															<AiFillPlusCircle />{' '}
														</span>
														<span>Add Project</span>
													</div>
												</Column>
											)}
											{partsArray.length > 1 && (
												<Column xs={3}>
													<div className='coop-add-remove-button' tabIndex='0' role='button' onClick={() => handleRemoveProjectItem()}>
														<span>
															<AiFillMinusCircle />{' '}
														</span>
														<span>Remove Project</span>
													</div>
												</Column>
											)}
										</Row>

										<Column className='sf-mt--lg' xs={12}>
											<Button color='black' type='submit' disabled={submitDisabled}>
												Submit
											</Button>
											{success && (
												<Alert className='sf-mt--lg' type='success' hideClose={true}>
													Your form has been successfully submitted.
												</Alert>
											)}
										</Column>
									</EloquaForm>
								</Container>
							</div>
						);
					}
				})}
		</Layout>
	);
};

General.propTypes = {
	data: PropTypes.object,
};

export default General;

export const query = graphql`
	query($uid: String!) {
		contentstackNewGeneral(uid: { eq: $uid }) {
			url
			hero {
				solid_color
				color
				image: hero_image {
					...Image
				}
			}
			top_content {
				heading
				description
			}
			modular_blocks {
				feature {
					image_position
					style
					video {
						...Video
					}
					image {
						...Image
					}
					heading
					description
					cta {
						...CTA
					}
				}
				general_content {
					heading
					description
					cta {
						...CTA
					}
					visibility_role
				}
				content_group {
					content_item {
						...ImageWithContent
					}
					cta {
						...CTA
					}
				}
				video_grid {
					group {
						video {
							...Video
						}
						internal_video {
							url
						}
						video_title
						description
						internal_video {
							url
						}
					}
				}
				form {
					form_type
				}
			}
		}
	}
`;
