import React, { Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { navigate } from "gatsby";
import sessionStorage from "sessionstorage";

import { Container, Row, Column, Button, TextInput } from "sash-foundation";

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";
import Nav from "../../../components/common/nav";

import "./index.scss";

class ChangePassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userName: "",
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
			errors: [],
			loginError: "",
			showErrors: false,
			error: ""
		};
		this.handleInput = this.handleInput.bind(this);
		this.submitHandler = this.submitHandler.bind(this);
		this.changePassword = this.changePassword.bind(this);
	}

	handleInput(e) {
		this.setState({
			[e.target.name]: e.target.value,
			errors: [],
			loginError: ""
		});
	}

	changePassword() {
		const sendUser = {
			userName: this.state.userName,
			password: this.state.currentPassword,
			newPassword: this.state.newPassword
		};

		axios({
			method: "post",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/changepassword`,
			//url: `http://localhost:4000/pella-coop/v1/user/changepassword`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			},
			data: sendUser
		}).then(response => {
			if (response.data.success === true) {
				const accessToken = response.data.token;
				const decoded = jwt_decode(response.data.token);
				const userEmail = decoded.email;
				const userCompany = decoded.companyName;
				const userFirstName = decoded.firstName;
				const userLastName = decoded.lastName;
				const userRole = decoded.userRole;

				let userInfo = {
					email: decoded.email,
					company: decoded.companyName,
					firstName: decoded.firstName,
					lastName: decoded.lastName,
					role: decoded.userRole
				};

				sessionStorage.setItem("pellaUser", accessToken);
				sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
				sessionStorage.setItem("userEmail", userEmail);
				sessionStorage.setItem("userCompany", userCompany);
				sessionStorage.setItem("userFirstName", userFirstName);
				sessionStorage.setItem("userLastName", userLastName);
				sessionStorage.setItem("userRole", userRole);
				sessionStorage.setItem("status", "authenticated");

				navigate("/account/change-password-success");
			} else {
				if (response.data.success === false) {
					this.setState({
						loginError: response.data.message
					});
				}
			}
		});
	}

	validateForm(userName, currentPassword, newPassword, confirmNewPassword) {
		let errors = [];

		if (userName.length === 0) {
			errors.userName = "Please enter your username.";
		}
		if (currentPassword.length === 0) {
			errors.currentPassword = "Please enter your current password.";
		}
		if (newPassword.length === 0) {
			errors.newPassword = "Please enter your new password.";
		}
		if (newPassword.length > 0) {
			// this.checkPassword(password);
			var passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
			if (!newPassword.match(passwordCheck)) {
				errors.newPassword =
					"The new password does not meet the requirements.";
			}
		}
		if (confirmNewPassword.length === 0) {
			errors.confirmNewPassword = "Please confirm your new password.";
		}

		if (newPassword.length > 0 && confirmNewPassword.length > 0) {
			if (!confirmNewPassword.match(newPassword)) {
				errors.newPassword = "The new passwords do not match.";
			}
		}

		return errors;
	}

	submitHandler(e) {
		e.preventDefault();
		//this.changePassword();
		const {
			userName,
			currentPassword,
			newPassword,
			confirmNewPassword
		} = this.state;

		const errors = this.validateForm(
			userName,
			currentPassword,
			newPassword,
			confirmNewPassword
		);

		if (Object.keys(errors).length > 0) {
			this.setState({
				errors: errors,
				showErrors: true
			});
		} else {
			this.changePassword();
		}
	}

	render() {
		const { loginError, showErrors, errors } = this.state;

		return (
			<Layout>
				<SEO title='Change Password' />
				{/* <Nav /> */}
				<Container className='sf-pb--2xl change-password'>
					<h1 style={{ fontSize: '2rem' }} className='sf-text--uppercase'>
						Change Password
					</h1>
					<hr />

					<fieldset className='sf-formGroup'>
						<legend className='sf-display--block'>Create Your New Password</legend>
						<Row>
							<Column xs='12'>
								<p style={{ color: '#a70215' }} className='sf-text--bold required-text'>
									All fields required.
								</p>
								<p style={{ color: '#a70215' }} className='sf-text--bold required-note'>
									NOTE: Passwords must be between 8 and 15
									characters in length, contain one uppercase
									letter, one lowercase letter, and one
									number.
								</p>
							</Column>
						</Row>
						{loginError ? (
							<Row>
								<Column xs='12'>
									<p style={{ color: '#a70215' }} className='sf-text--bold'>
										{loginError}
									</p>
								</Column>
							</Row>
						) : null}
						{showErrors ? (
							<Row>
								<Column xs='12'>
									<p style={{ color: '#a70215' }} className='sf-text--bold'>
										{errors.userName}{" "}
										{errors.currentPassword}{" "}
										{errors.newPassword}{" "}
										{errors.confirmNewPassword}
									</p>
								</Column>
							</Row>
						) : null}
						<form onSubmit={this.submitHandler}>
							<Row>
								<Column xs='12' sm='6'>
									<TextInput
										type='text'
										id='userName'
										labelText='Username'
										placeholder='Username'
										tabIndex='1'
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' sm='6'>
									<TextInput
										type='password'
										id='newPassword'
										labelText='New Password'
										placeholder='New Password'
										tabIndex='3'
										onChange={this.handleInput}
									/>
								</Column>
							</Row>
							<Row>
								<Column xs='12' sm='6'>
									<TextInput
										type='password'
										id='currentPassword'
										labelText='Current Password'
										placeholder='Current Password'
										tabIndex='2'
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' sm='6'>
									<TextInput
										type='password'
										id='confirmNewPassword'
										labelText='Confrim New Password'
										placeholder='Confrim New Password'
										tabIndex='4'
										onChange={this.handleInput}
									/>
								</Column>
							</Row>
							<Row>
								<Column xs='12' className='sf-text--right'>
									<Button
										color='black'
										type='submit'
									>
										Update My Password
									</Button>
								</Column>
							</Row>
						</form>
					</fieldset>
				</Container>
			</Layout>
		);
	}
}
export default ChangePassword;
