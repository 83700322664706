/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import { graphql, Link } from "gatsby";
import axios from "axios";
import sessionStorage from "sessionstorage";
import moment from "moment";
import { MdChevronLeft } from 'react-icons/md';

import { Container, Row, Column, Alert } from "sash-foundation";

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";
import OrderProgress from "./orderprogress";

import "./index.scss";

// import fakeOrders from "./fakeOrders.json";

const OrderStatus = (props) => {

	const statusData = props.data.contentstackOrderTracking.modular_blocks[0].order_status;
	const needHelpData = props.data.contentstackOrderTracking.modular_blocks[1].need_help;
	// const promoData = props.data.contentstackOrderTracking.modular_blocks[2].promo.promo_ribbon[0];

	const [orderData, setOrderData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState('');
	const [showErrors, setShowErrors] = useState(false);

	useEffect(() => {
		const storageNumbers = sessionStorage.getItem("poNumbers");
		const poNumbers  = JSON.parse(storageNumbers);

		let poNumberList = [];

		for(let i = 0; i < poNumbers.length; i++) {
			poNumberList.push(`"${poNumbers[i]}"`);
		}

		const loadNewApiOrders =  () => {
			setLoading(true);
			// if(fakeOrders.success === true) {
			// 	setOrderData(fakeOrders.response.orders);
			// 	setLoading(false);
			// }
			axios({
				method: 'get',
				url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/orders?po_nums=[${poNumberList}]`,
				headers: {
					Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
				}
			}).then(response => {
				if(response.data.success === true) {
					setOrderData(response.data.response.orders);
					setLoading(false);
				}
				if(response.data.success === false) {
					setShowErrors(true);
					setErrors('Something went wrong. Please go back to the order lookup page and try again.');
					setLoading(false);
				}
			}).catch(error => {
				console.log('error :>> ', error);
				setShowErrors(true);
				setErrors('Something went wrong. Please go back to the order lookup page and try again.');
				setLoading(false);
			});
		};

		if(storageNumbers) {
			loadNewApiOrders();
		}

	}, []);

	// console.log('orderData :>> ', orderData);

	return (
		<Layout>
			{loading ? (
				<div id='overlay' className='spinner'>
					<img src={require("../../../images/spinner.svg")} />
				</div>) :
			<>
			<SEO title='Order Status' />
			<Container  width={1400} className='sf-pb--2xl orders'>
				<div>
					<Link to='/orders/orderlookup/' className='orderDetails-back'>
						<MdChevronLeft /> Back to Order Lookup
					</Link>
					<h1 style={{ fontSize: '2rem', color: '#676767' }} className='sf-text--uppercase sf-text--semibold'>
						Order Lookup Results
					</h1>
					<hr />

					{showErrors && errors && <Alert type='error'>{errors}</Alert>}

				</div>
				<Row>
					<Column xs='12' sm='12' md='12' lg='8' xl='9'>
						<div className='sf-mb--xl'>
							{orderData && !loading && orderData.map((order, index) =>  {
								let noOrder = false;
								let hasArrivalDate = false;
								if (order.status === 'Order Not Found' ) {
									noOrder = true;
								}
								if(order.est_arrival_date) {
									hasArrivalDate = true;
								}
								else {
									hasArrivalDate = false;
								}
								const d = new Date(order.est_arrival_date);
								let displayDay = '';
								let day = d.getDay();
								if( day === 0) {
									displayDay = 'Sunday';
								}
								if( day === 1) {
									displayDay = 'Monday';
								}
								if( day === 2) {
									displayDay = 'Tuesday';
								}
								if( day === 3) {
									displayDay = 'Wednesday';
								}
								if( day === 4) {
									displayDay = 'Thursday';
								}
								if( day === 5) {
									displayDay = 'Friday';
								}
								if( day === 6) {
									displayDay = 'Saturday';
								}

								return (
									<div key={index} className='order-card'>
										<div className='order-card-top'>
											<div className='order-card-text'>
												<h2>PO# {order.po_num}</h2>
												<div><span className='order-item-bold' >Date Ordered: </span>{order.order_date}</div>
												<div><span className='order-item-bold' >Store: </span>{order.store}</div>
											</div>
											{order && order.status === 'Shipped' && hasArrivalDate &&
											<div className='order-date'>
												<div className='order-date-wrapper'>
													<div className='order-date-top'>
														{displayDay}
													</div>
													<div className='order-date-bottom'>
														<div className='order-date-month'>{moment(order.est_arrival_date).format('MMM')} </div>
														<div className='order-date-day'>{moment(order.est_arrival_date).format('DD')}</div>
													</div>
												</div>
												<span className='order-date-text'>{statusData?.status[2]?.arrival_date}</span>
											</div>
											}
										</div>
										<div>
											{!noOrder
												?
												<OrderProgress orderStatus={order.status} progressContent={statusData} />
												:
												<div className='order-not-found'>
													<h3>Your PO was not found. Please verify your PO# and try again.</h3>
												</div>

											}

										</div>
									</div>
								);})}

						</div>
						{/* <div>
							<div className='cta-card mb-30'>
								<div className='cta-card-image-column'>
									<img src={promoData.background_image.url} alt={promoData.background_image.title} />
								</div>
								<div className='cta-card-text-column'>
									<h3>{promoData.heading}</h3>
									<p>{promoData.subheading}</p>
									<Button href={promoData.cta[0]?.link[0].destination} color='black' gtm-linktype={promoData.cta[0].gtm_link_type} gtm-linktext={promoData.cta[0].cta_title} >{promoData.cta[0].cta_title}</Button>
									<p>{promoData.right_text}</p>
								</div>
							</div>
						</div> */}
					</Column>
					<Column xs='12' sm='12' md='12' lg='4' xl='3'>
						<div className='content-card'>
							<h3>{needHelpData.heading}</h3>
							{needHelpData?.group.map((item, index) => (
								<div className='orders-help-row' key={index}>
									<div> <img className='orders-help-image' src={item.icon?.url} alt={item.icon?.title}/></div>
									<div  dangerouslySetInnerHTML={{ __html: item.description }} />
								</div>
							))}
						</div>
					</Column>
				</Row>
			</Container>
			</>
			}
		</Layout>
	);
};

export default OrderStatus;

export const query = graphql `
query {
	contentstackOrderTracking(uid: {eq: "blt05e040fcc0a73e42"}) {
		modular_blocks {
		  need_help {
			heading
			group {
			  description
			  icon {
				url
				title
			  }
			}
		  }
		  order_status {
			status {
			  heading
			  subheading
			  arrival_date
			  file {
				url
			  }
			}
		  }
		}
	  }
  }

`;
