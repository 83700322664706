/* eslint-disable react/prop-types */
import React, { useState, useEffect} from 'react';
import { Container, Row, Checkbox, Button, TextInput } from "sash-foundation";

const SwatRoomForm = (props) => {
	const {values, setValues, handleViewSection, formType, errors, expanded} = props;
	const [previousSection, setPreviousSection] = useState('');
	const [otherRoom, setOtherRoom] = useState(false);
	const [otherRoom2, setOtherRoom2] = useState('');

	useEffect(() => {
		if (formType === 'PartsWithService') {
			setPreviousSection('ServiceInfo');
		} else {
			setPreviousSection('JobInfo');
		}
	}, [formType]);

	// useEffect(() => {
	// 	console.log(values);
	// 	console.log(otherRoom2);
	// });

	const handleDescriptionChecked =  event => {
		const field = event.target.name;
		let newArray = [...values.roomDescription];
		if (newArray.includes(event.target.value) ) {
			newArray = newArray.filter(value => value !== event.target.value);
			setValues({...values, roomDescription:newArray});
		} else {
			newArray = [...newArray, event.target.value];
			setValues({...values, roomDescription:newArray});
		}
	};

	const handleOtherChecked = event => {
		setValues({...values, roomDescOther: "Other - " + event.target.value});
		setOtherRoom2(event.target.value);
	};

	const handleOther = event => {
		if(otherRoom === true) {
			setOtherRoom(false);
		}
		else {
			setOtherRoom(true);
		}

	};

	return (
		<Container>
			<Row>
				<fieldset className='sf-formGroup'>
					<legend className='sf-display--block required'>Check all that apply<span style={{ color: '#a70215' }}> *</span></legend>
					<Checkbox type='checkbox' value='Foyer' group='roomDescription' labelText='Foyer' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Foyer')} />
					<Checkbox type='checkbox' value='Family/Living Room' group='roomDescription' labelText='Family/Living Room' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Family/Living Room')} />
					<Checkbox type='checkbox' value='Dining Room' group='roomDescription' labelText=' Dining Room' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Dining Room')}/>
					<Checkbox type='checkbox' value='Bedroom' group='roomDescription' labelText='Bedroom' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Bedroom')}/>
					<Checkbox type='checkbox' value='Bathroom' group='roomDescription' labelText='Bathroom' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Bathroom')}/>
					<Checkbox type='checkbox' value='Kitchen' group='roomDescription' labelText='Kitchen' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Kitchen')}/>
					<Checkbox type='checkbox' value='Office/Den' group='roomDescription' labelText='Office/Den' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Office/Den')}/>
					<Checkbox type='checkbox' value='Garage' group='roomDescription' labelText='Garage' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Garage')}/>
					<Checkbox type='checkbox' value='Storage Room' group='roomDescription' labelText=' Storage Room' onChange={(event) => handleDescriptionChecked(event)} checked={values.roomDescription.includes('Storage Room')}/>
					<Checkbox 
						type='checkbox' 
						value='Other'
						group='roomDescription'
						labelText='Other - '
						onChange={(event) => handleOther(event)}
						checked={otherRoom}
					>
					</Checkbox>
					{otherRoom &&
						<TextInput
							name='other2'
							value={otherRoom2}
							onChange={(event) => handleOtherChecked(event)}
						/>
					}
				</fieldset>
			</Row>
			{
				errors.roomDescription &&
				<Row><div className='error-text'>{errors.roomDescription}</div></Row>

			}

			{ !expanded &&
				<Row className='sf-justify-content--end'>
					<Button className='sf-mr--md' type='button' color='white' onClick={() => handleViewSection(previousSection)} >Previous</Button>
					<Button color='primary' type='button' onClick={() => handleViewSection('FloorInfo')} >Next</Button>
				</Row>
			}

		</Container>
	);
};

export default SwatRoomForm;
