import React, { Component } from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import jwt_decode from "jwt-decode";
import { navigate } from "gatsby";
import sessionStorage from "sessionstorage";
import { Container, Row, Column, TextInput, Button, Modal, Alert } from "sash-foundation";

import { isLoggedIn } from "../../components/common/private-route/private-route";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import "./login.scss";

class Login extends Component {
	constructor(props) {

		super(props);

		this.state = {
			userName: "",
			password: "",
			errors: [],
			loginError: "",
			passwordModalOpen: false,
			registerModalOpen: false,
			error: "",
			loading: false,
			showSuccess: false,
			successMessage: ''
		};

		this.handleInput = this.handleInput.bind(this);
		this.submitHandler = this.submitHandler.bind(this);
		this.login = this.login.bind(this);
		this.onPasswordModalTrigger = this.onPasswordModalTrigger.bind(this);
		this.onRegisterModalTrigger = this.onRegisterModalTrigger.bind(this);
		// this.onPasswordModalCloseClick = this.onPasswordModalCloseClick.bind(this);
		this.onRegisterModalCloseClick = this.onRegisterModalCloseClick.bind(this);
	}

	componentDidMount() {
		// Save the parameters before the login page re-renders
		if (this.props.location.state != null && this.props.location.state.prevUrl) {
			if (this.props.location.state.params != null && this.props.location.state.params !== "") {
				this.setState({
					prevUrl: this.props.location.state.prevUrl,
					params: this.props.location.state.params
				});
			} else {
				this.setState({
					prevUrl: this.props.location.state.prevUrl
				});
			}
		}
		if (this.props.location.state != null && this.props.location.state.passwordResetSuccess) {
			this.setState({
				showSuccess: true,
				successMessage: 'Your password was reset successfully. Please log in with your new password.'
			});
		}
	}

	login() {
		const sendLogin = {
			userName: this.state.userName,
			password: this.state.password
		};

		axios({
			method: "post",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/login`,

			//url: `http://localhost:4000/pella-coop/v1/user/login`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			},
			data: sendLogin
		}).then(response => {
			if (response.data.success === true) {
				const accessToken = response.data.token;
				const decoded = jwt_decode(response.data.token);
				const userName = decoded.userName;
				const userEmail = decoded.email;
				const userCompany = decoded.companyName;
				const userFirstName = decoded.firstName;
				const userLastName = decoded.lastName;
				const userRole = decoded.userRole;
				const companyList = decoded.companyList;

				let userInfo = {
					username: decoded.userName,
					email: decoded.email,
					company: decoded.companyName,
					firstName: decoded.firstName,
					lastName: decoded.lastName,
					role: decoded.userRole,
					repCompanies: companyList
				};

				sessionStorage.setItem("pellaUser", accessToken);
				sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
				sessionStorage.setItem("userEmail", userEmail);
				sessionStorage.setItem("userName", userName);
				sessionStorage.setItem("userCompany", userCompany);
				sessionStorage.setItem("userFirstName", userFirstName);
				sessionStorage.setItem("userLastName", userLastName);
				sessionStorage.setItem("userRole", userRole);
				sessionStorage.setItem("status", "authenticated");

				// Check if there is a prevUrl and redirect to that
				if (this.state.prevUrl !== '/login/') {
					// Check if there are parameters
					if (this.state.params) {
						navigate(this.state.prevUrl + this.state.params);
					} else {
						navigate(this.state.prevUrl);
					}
				} else {
					navigate("/");
				}
			} else {
				if (response.data.success === false) {
					this.setState({
						loginError: response.data.message
					});
				}
			}
		});
	}

	onPasswordModalTrigger() {
		this.setState({
			passwordModalOpen: true
		});
	}

	onRegisterModalTrigger() {
		this.setState({
			registerModalOpen: true
		});
	}

	// onPasswordModalCloseClick() {
	// 	this.setState({
	// 		passwordModalOpen: false
	// 	});
	// }
	onRegisterModalCloseClick() {
		this.setState({
			registerModalOpen: false
		});
	}
	handleInput(e) {
		this.setState({
			[e.target.name]: e.target.value,
			errors: [],
			loginError: ""
		});
	}

	validateForm(userName, password) {
		let errors = [];

		if (userName.length === 0) {
			errors.userName = "Username is required.";
		}
		if (password.length === 0) {
			errors.password = "Password is required.";
		}

		return errors;
	}

	submitHandler(e) {
		e.preventDefault();

		const { userName, password } = this.state;

		const errors = this.validateForm(userName, password);

		if (Object.keys(errors).length > 0) {
			this.setState({
				errors: errors
			});
		} else {
			this.login();

			this.setState({
				loading: true
			});
		}
	}

	render() {
		if (isLoggedIn()) {
			navigate("/");
		}

		const { errors, loginError, showSuccess, successMessage } = this.state;

		return (
			<Layout hideLinks={true} className='login'>
				<div className='page-topper'></div>
				<SEO title='Login' />
				<Container className='login-main-section sf-pt--2xl'>
					<Row align='center'>
						<Column  xs='12'>
							<h1 className='landing-page-title'>
								Pella Dealer Portal
							</h1>
							<div className='login-bg'>
								<div className='login-container'>
									<div className='login-wrapper'>
										<Row>
											<Column xs='12'>
												<h2 className='log-title'>
													Please enter your username
													and password.<br />
													<span><strong>NOTE:</strong> Username and passwords are case sensitive.</span>
												</h2>
												<hr />
											</Column>
										</Row>

										{loginError ? (
											<Row className='error'>
												<Column xs='12'>
													<p>{loginError}</p>
												</Column>
											</Row>
										) : null}
										{errors.userName || errors.password ? (
											<Row className='error'>
												<Column xs='12'>
													<h3 className='error-title'>
														Please correct the
														following error(s):
													</h3>
													{errors.userName ? (
														<p>{errors.userName}</p>
													) : null}
													{errors.password ? (
														<p>{errors.password}</p>
													) : null}
												</Column>
											</Row>
										) : null}

										{showSuccess &&
											<Row className='error'>
												<Column xs='12'>
													<Alert type='success'>{successMessage}</Alert>
												</Column>
											</Row>
										}

										<Row>
											<Column xs='12'>
												<form
													onSubmit={
														this.submitHandler
													}
												>
													<TextInput
														type='text'
														id='userName'
														labelText='User Name'
														placeholder='User Name'
														hideLabel
														onChange={
															this.handleInput
														}
													></TextInput>

													<TextInput
														type='password'
														id='password'
														labelText='Password'
														placeholder='Password'
														hideLabel
														onChange={
															this.handleInput
														}
													></TextInput>

													<Button
														type='submit'
														color='black'
														className='login-button'
													>
														Sign In
													</Button>
												</form>
											</Column>
										</Row>
										<Row>
											<Column xs='12' sm='6'>
												<a href='/account/forgot-password' >
													Reset Password?
												</a>
											</Column>
											<Column xs='12' sm='6'>
												<a href='/account/registration'>Need to Register?</a>
												{/* <a
													className='login-link'
													role='button'
													onClick={
														this
															.onRegisterModalTrigger
													}
												>
													Need to Register?
												</a> */}
											</Column>
										</Row>
									</div>
								</div>
							</div>
						</Column>
					</Row>
				</Container>
				{/* <Modal
					dialog
					isOpen={this.state.passwordModalOpen}
					onClose={this.onPasswordModalCloseClick}
				>
					<Modal.Header>Forgot Your Password?</Modal.Header>
					<Modal.Content>
						<p>
							Please email Customer Support for assistance{" "}
							<a href='mailto:portalsupport@pella.com'>
								portalsupport@pella.com.
							</a>
						</p>
					</Modal.Content>
					<Modal.Footer className='text-right'>
						<Button
							color='black'
							onClick={this.onPasswordModalCloseClick}
						>
							Close
						</Button>
					</Modal.Footer>
				</Modal> */}
				<Modal
					dialog
					isOpen={this.state.registerModalOpen}
					onClose={this.onRegisterModalCloseClick}
				>
					<Modal.Header>Registration</Modal.Header>
					<Modal.Content>
						<p>
							To register for this website, please click{" "}
							<a href='/account/registration'>here</a>.
						</p>
						<p>
							Please email{""}{" "}
							<a href='mailto:portalsupport@pella.com'>
								Customer Service
							</a>{" "}
							if you need additional help.
						</p>
					</Modal.Content>
					<Modal.Footer className='sf-text--right'>
						<Button
							color='black'
							onClick={this.onRegisterModalCloseClick}
						>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</Layout>
		);
	}
}

Login.propTypes = {
	location: PropTypes.object
};

export default Login;
