import React, { Component } from "react";
import axios from "axios";
import moment from 'moment';
import sessionStorage from "sessionstorage";
import { Container, Row, Column, Select, Checkbox, TextInput, Button, Modal } from "sash-foundation";

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";
import Nav from "../../../components/common/nav";

import "./index.scss";

class UserAdmin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			intervalId: 0,
			loading: true,
			dirtyForm: false,
			corpUsers: [],
			editUser: {},
			editUserSave: false,
			submitActive: false,
			showSuccess: false,
			showErrors: false,
			errors: [],
			successMessage: "",
			id: "",
			firstName: "",
			lastName: "",
			email: "",
			userRole: "",
			password: "",
			userName: "",
			companyName: "Pella",
			companyCode: "pella",
			address: "",
			city: "",
			state: "",
			zip: "",
			repABCSupply: false,
			repBuilderFirstSource: false,
			repCarterLumber: false,
			repHammondLumber: false,
			repMcCoy: false,
			repNationalLumber: false,
			repProDealers: false,
			repAllianceLumber: false,
			passwordToggle: false,
			isAdmin: sessionStorage.getItem('userRole') || "",
			isUser: sessionStorage.getItem('userName') || "",
			displayAlert: false,
			alertType: '',
			alertMessage: '',
			removeUserUserId: '',
			removeUserModalOpen: false,
		};
		this.handleInput = this.handleInput.bind(this);
		this.saveCorpUser = this.saveCorpUser.bind(this);
		this.clearEdit = this.clearEdit.bind(this);
		this.newCorpUser = this.newCorpUser.bind(this);
		//this.editCorpUser = this.editCorpUser.bind(this);
		this.togglePassword = this.togglePassword.bind(this);
		this.removeInput = this.removeInput.bind(this);
		this.onAlertCloseClick = this.onAlertCloseClick.bind(this);
		this.removeUser = this.removeUser.bind(this);
		this.onRemoveUserModalTrigger = this.onRemoveUserModalTrigger.bind(this);
		this.removeUserCloseClick = this.removeUserCloseClick.bind(this);
	}
	componentDidMount() {

		if (this.state.isAdmin !== 'ADMIN') {
			navigate('/', { state: {
				prevUrl: window.location.pathname,
				params: window.location.search
			}});
		}

		axios({
			method: "get",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/corpusers`,
			//url: `http://localhost:4000/pella-coop/v1/user/corpusers`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			}
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					corpUsers: response.data.response,
					loading: false
				});
			} else {
				if (response.data.success === false) {
					this.setState({
						corpUsers: [],
						loading: false
					});
				}
			}
		});
	}

	togglePassword() {

		if(this.state.passwordToggle === true){
			this.setState({
				passwordToggle: false,
			});
		}
		else {
			this.setState({
				passwordToggle: true ,
			});
		}

	}

	removeInput() {
		this.setState({
			password: "",
		});
	}

	handleInput(event) {
		const target = event.target;
		const value =
			target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
			dirtyForm: true,
			showSuccess: false,
			successMessage: "",
			errors: [],
			removeError: ''
		});
	}

	onSelectCompanyClick(name) {
		if (
			this.state[name] == "TRUE" ||
			this.state[name] == "true" ||
			this.state[name] == true
		) {
			this.setState({
				[name]: false,
				dirtyForm: true,
				showSuccess: false,
				successMessage: ""
			});
		} else {
			this.setState({
				[name]: true,
				dirtyForm: true,
				showSuccess: false,
				successMessage: ""
			});
		}
	}

	clearEdit() {
		this.setState({
			dirtyForm: false,
			submitActive: false,
			id: "",
			firstName: "",
			lastName: "",
			email: "",
			userRole: "",
			password: "",
			userName: "",
			repABCSupply: "",
			repBuilderFirstSource: "",
			repCarterLumber: "",
			repHammondLumber: "",
			repMcCoy: "",
			repNationalLumber: "",
			repProDealers: "",
			repAllianceLumber:"",
			showSuccess: false,
			showErrors: false,
			errors: [],
			successMessage: "",
			editUserSave: false
		});
	}

	editCorpUser(username) {
		window.scrollTo(0, 0);
		axios({
			method: "get",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/corpuser/${username}`,
			//url: `http://localhost:4000/pella-coop/v1/user/corpuser/${username}`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			}
		}).then(response => {
			const editUser = response.data.response;

			this.setState({
				id: editUser._id,
				firstName: editUser.firstName,
				lastName: editUser.lastName,
				email: editUser.email,
				userRole: editUser.userRole,
				password: editUser.password,
				userName: editUser.userName,
				companyName: editUser.companyName,
				companyCode: editUser.companyCode,
				address: editUser.address,
				city: editUser.city,
				state: editUser.state,
				zip: editUser.zip,
				repABCSupply: editUser.repABCSupply,
				repBuilderFirstSource: editUser.repBuilderFirstSource,
				repCarterLumber: editUser.repCarterLumber,
				repHammondLumber: editUser.repHammondLumber,
				repMcCoy: editUser.repMcCoy,
				repNationalLumber: editUser.repNationalLumber,
				repProDealers: editUser.repProDealers,
				repAllianceLumber: editUser.repAllianceLumber,
				editUserSave: true,
				dirtyForm: true,
				submitActive: true,
				showSuccess: false,
				successMessage: "",
				removeError: ''
			});
		});
	}

	validateForm(firstName, lastName, email, password, userName, userRole) {

		let errors = [];

		if (firstName.length === 0) {
			errors.firstName = "Please provide a first name.";
		}
		if (lastName.length === 0) {
			errors.lastName = "Please provide a last name.";
		}
		if (password?.length === 0) {
			errors.password = "Please provide a password.";
		}

		if (email.length === 0) {
			errors.email = "Please provide a valid email address.";
		}

		if (password?.length > 0) {
			// this.checkPassword(password);
			var passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;

			if (!password.match(passwordCheck)) {
				errors.password =
					"Please make sure your desired password is between 8 and 15 characters in length, contains one uppercase letter, one lowercase letter, and one number.";
			}
		}

		if (userName.length === 0) {
			errors.userName = "Please provide a username.";
		}
		if (userRole.length === 0) {
			errors.userRole = "Please select a role.";
		}
		if (userRole === "select") {
			errors.userRole = "Please select a role.";
		}

		return errors;
	}

	saveCorpUser() {
		const {
			firstName,
			lastName,
			email,
			password,
			userName,
			userRole
		} = this.state;

		const errors = this.validateForm(
			firstName,
			lastName,
			email,
			password,
			userName,
			userRole
		);

		if (Object.keys(errors).length > 0) {
			this.setState({
				errors: errors,
				showErrors: true
			});
		} else {
			axios({
				method: "put",
				url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/corpuser`,
				//url: `http://localhost:4000/pella-coop/v1/user/corpuser`,
				headers: {
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + process.env.GATSBY_PELLA_API_TOKEN
				},
				data: {
					id: this.state.id,
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					email: this.state.email,
					userRole: this.state.userRole,
					password: this.state.password,
					userName: this.state.userName,
					repABCSupply: this.state.repABCSupply,
					repBuilderFirstSource: this.state.repBuilderFirstSource,
					repCarterLumber: this.state.repCarterLumber,
					repHammondLumber: this.state.repHammondLumber,
					repMcCoy: this.state.repMcCoy,
					repNationalLumber: this.state.repNationalLumber,
					repProDealers: this.state.repProDealers,
					repAllianceLumber: this.state.repAllianceLumber,
					companyName: "Pella",
					companyCode: "pella",
					address: "",
					city: "",
					state: "",
					zip: ""
				}
			}).then(response => {
				if (response.data.success == false) {
					this.setState({
						showSuccess: true,
						successMessage: response.data.message
					});
				} else {
					const editUser = response.data.response;
					this.setState({
						id: editUser._id,
						firstName: editUser.firstName,
						lastName: editUser.lastName,
						email: editUser.email,
						userRole: editUser.userRole,
						password: editUser.password,
						userName: editUser.userName,
						repABCSupply: editUser.repABCSupply,
						repBuilderFirstSource: editUser.repBuilderFirstSource,
						repCarterLumber: editUser.repCarterLumber,
						repHammondLumber: editUser.repHammondLumber,
						repMcCoy: editUser.repMcCoy,
						repNationalLumber: editUser.repNationalLumber,
						repProDealers: editUser.repProDealers,
						repAllianceLumber: editUser.repAllianceLumber,
						showSuccess: true,
						successMessage: "The user was saved successfully.",
						dirtyForm: true
					});
				}
			});
		}
	}

	newCorpUser() {
		const {
			firstName,
			lastName,
			email,
			password,
			userName,
			userRole
		} = this.state;

		const errors = this.validateForm(
			firstName,
			lastName,
			email,
			password,
			userName,
			userRole
		);

		if (Object.keys(errors).length > 0) {
			this.setState({
				errors: errors,
				showErrors: true
			});
		} else {
			axios({
				method: "post",
				url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/corpuser`,
				//url: `http://localhost:4000/pella-coop/v1/user/corpuser`,
				headers: {
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + process.env.GATSBY_PELLA_API_TOKEN
				},
				data: {
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					email: this.state.email,
					userRole: this.state.userRole,
					password: this.state.password,
					userName: this.state.userName,
					repABCSupply: this.state.repABCSupply,
					repBuilderFirstSource: this.state.repBuilderFirstSource,
					repCarterLumber: this.state.repCarterLumber,
					repHammondLumber: this.state.repHammondLumber,
					repMcCoy: this.state.repMcCoy,
					repNationalLumber: this.state.repNationalLumber,
					repProDealers: this.state.repProDealers,
					repAllianceLumber: this.state.repAllianceLumber,
					companyName: this.state.companyName,
					companyCode: this.state.companyCode,
					address: this.state.address,
					city: this.state.city,
					state: this.state.state,
					zip: this.state.zip
				}
			}).then(response => {
				const returnUser = response.data;
				if (response.data.success === false) {
					this.setState({
						showErrors: true,
						removeError: response.data.message,
						loading: false
					});
				} else {
					this.setState({
						corpUsers: returnUser.allUsers,
						firstName: returnUser?.userResult?.firstName,
						lastName: returnUser?.userResult?.lastName,
						email: returnUser?.userResult?.email,
						userRole: returnUser?.userResult?.userRole,
						password: returnUser?.userResult?.password,
						userName: returnUser?.userResult?.userName,
						repABCSupply: returnUser?.userResult?.repABCSupply,
						repBuilderFirstSource:
							returnUser?.userResult?.repBuilderFirstSource,
						repCarterLumber: returnUser?.userResult?.repCarterLumber,
						repHammondLumber: returnUser?.userResult?.repHammondLumber,
						repMcCoy: returnUser?.userResult?.repMcCoy,
						repNationalLumber: returnUser?.userResult?.repNationalLumber,
						repProDealers: returnUser?.userResult?.repProDealers,
						repAllianceLumber: returnUser?.userResult?.repAllianceLumber,
						showSuccess: true,
						successMessage: returnUser.successMessage,
						dirtyForm: true,
						editUserSave: true
					});
				}

			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.dirtyForm != this.state.dirtyForm) {
			if (this.state.dirtyForm === false) {
				this.setState({
					submitActive: false
				});
			}
			if (this.state.dirtyForm === true) {
				this.setState({
					submitActive: true
				});
			}
		}
	}

	onRemoveUserModalTrigger(userId){
		this.setState({
			removeUserModalOpen: true,
			removeUserUserId: userId,
		});
	}

	removeUser (userId) {

		this.setState({
			loading: true,
			removeUserModalOpen:false,
			removeError: ''
		});
		axios({
			method: 'delete',
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/user/corpuser/${userId}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
			},
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					corpUsers: response.data.response,
					showSuccess: true,
					successMessage: "The user was removed successfully.",
					loading: false
				});
			}
			if (response.data.success === false) {
				this.setState({
					showErrors: true,
					removeError: response.data.message,
					loading: false
				});
			}
		});
	}

	removeUserCloseClick() {
		this.setState({
			removeUserModalOpen: false
		});
	}

	onAlertCloseClick() {
		this.setState({
			displayAlert: false
		});
	}

	formatDate = (value) => {
		let returnDate = value.toString();
		return moment(returnDate).format('MM/DD/YYYY hh:mm');
	}

	render() {
		const {
			loading,
			submitActive,
			corpUsers,
			firstName,
			lastName,
			email,
			userName,
			userRole,
			password,
			repABCSupply,
			repBuilderFirstSource,
			repCarterLumber,
			repHammondLumber,
			repMcCoy,
			repNationalLumber,
			repAllianceLumber,
			repProDealers,
			showSuccess,
			showErrors,
			errors,
			successMessage,
			editUserSave
		} = this.state;

		return (
			<Layout>
				<SEO title={"User Admin"} />

				{/* <Nav /> */}
				<Container width={1200} className='sf-pb--2xl user-admin'>
					<h1 style={{ fontSize: '2rem' }} className='sf-text--uppercase'>User Admin</h1>
					<hr />

					<fieldset className='sf-formGroup'>
						<legend className='sf-display--block required'>Add/Edit User</legend>
						<Row>
							<Column xs='12'>
								<p style={{ color: '#a70215' }} className='sf-text--bold required-text'>
									All fields required except where indicated.
								</p>
								<p style={{ color: '#a70215' }} className='sf-text--bold required-note'>
									NOTE: Passwords must be between 8 and 15
									characters in length, contain one uppercase
									letter, one lowercase letter, and one
									number.
								</p>
							</Column>
						</Row>
						<Row className='user-admin-inputs'>
							<Column xs='12' md='4'>
								<TextInput
									type='text'
									id='firstName'
									name='firstName'
									labelText='First Name'
									placeholder='First Name'
									onChange={this.handleInput}
									value={firstName || ""}
								/>
							</Column>
							<Column xs='12' md='4'>
								<TextInput
									type='text'
									id='lastName'
									name='lastName'
									labelText='Last Name'
									placeholder='Last Name'
									onChange={this.handleInput}
									value={lastName || ""}
								/>
							</Column>
							<Column xs='12' md='4'>
								<Select
									id='userRole'
									name='userRole'
									labelText='Role'
									value={userRole || ""}
									onChange={this.handleInput}
								>
									<option value='select'>--Select--</option>
									<option value='ADMIN'>ADMIN</option>
									<option value='DSM'>DSM</option>
									<option value='REP'>REP</option>
								</Select>
							</Column>

							<Column xs='12' md='4'>
								<TextInput
									type='text'
									id='email'
									name='email'
									labelText='Email'
									placeholder='Email'
									onChange={this.handleInput}
									value={email || ""}
								/>
							</Column>
							<Column xs='12' md='4'>
								<TextInput
									type='text'
									id='userName'
									name='userName'
									labelText='Username'
									placeholder='Username'
									onChange={this.handleInput}
									value={userName || ""}
								/>
							</Column>
							<Column xs='12' md='4'>
								<TextInput
									type={this.state.passwordToggle ? "text" : "password"}
									id='password'
									name='password'
									labelText='Password'
									placeholder='Password'
									onChange={this.handleInput}
									onClick={this.removeInput}
									value={password || ""}
								/>
							</Column>
						</Row>
						<Row>
							<Column xs='12'>
								<p className='sf-mb--0'>
									Assigned to Builder(s)
									<span style={{ color: '#a70215' }} className='sf-ml--5 sf-text--bold'>
										&nbsp;Use for non-admin roles only
									</span>
								</p>
								<p style={{ color: '#a70215' }} className='sf-ml--5 sf-text--bold'>
									Not required
								</p>
							</Column>
						</Row>
						<div className='admin-holder'>
							<Row>
								<Column xs='12' md='3'>
									<Checkbox
										type='checkbox'
										id='repABCSupply'
										name='repABCSupply'
										labelText='ABC Supply Co. Inc.'
										checked={
											repABCSupply == "TRUE" ||
											repABCSupply == "true" ||
											repABCSupply == true ||
											""
										}
										onClick={() =>
											this.onSelectCompanyClick(
												"repABCSupply"
											)
										}
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='3'>
									<Checkbox
										type='checkbox'
										id='repBuilderFirstSource'
										name='repBuilderFirstSource'
										labelText='Builder’s FirstSource'
										checked={
											repBuilderFirstSource == "TRUE" ||
											repBuilderFirstSource == "true" ||
											repBuilderFirstSource == true ||
											""
										}
										onClick={() =>
											this.onSelectCompanyClick(
												"repBuilderFirstSource"
											)
										}
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='3'>
									<Checkbox
										type='checkbox'
										id='repCarterLumber'
										name='repCarterLumber'
										labelText='Carter Lumber'
										checked={
											repCarterLumber == "TRUE" ||
											repCarterLumber == "true" ||
											repCarterLumber == true ||
											""
										}
										onClick={() =>
											this.onSelectCompanyClick(
												"repCarterLumber"
											)
										}
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='3'>
									<Checkbox
										type='checkbox'
										id='repHammondLumber'
										name='repHammondLumber'
										labelText='Hammond Lumber Company'
										checked={
											repHammondLumber == "TRUE" ||
											repHammondLumber == "true" ||
											repHammondLumber == true ||
											""
										}
										onClick={() =>
											this.onSelectCompanyClick(
												"repHammondLumber"
											)
										}
										onChange={this.handleInput}
									/>
								</Column>
							</Row>
							<Row>
								<Column xs='12' md='3'>
									<Checkbox
										type='checkbox'
										id='repMcCoy'
										name='repMcCoy'
										labelText='McCoy’s Building Supply'
										checked={
											repMcCoy == "TRUE" ||
											repMcCoy == "true" ||
											repMcCoy == true ||
											""
										}
										onClick={() =>
											this.onSelectCompanyClick(
												"repMcCoy"
											)
										}
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='3'>
									<Checkbox
										type='checkbox'
										id='repNationalLumber'
										name='repNationalLumber'
										labelText='National Lumber'
										checked={
											repNationalLumber == "TRUE" ||
											repNationalLumber == "true" ||
											repNationalLumber == true ||
											""
										}
										onClick={() =>
											this.onSelectCompanyClick(
												"repNationalLumber"
											)
										}
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='3'>
									<Checkbox
										type='checkbox'
										id='repProDealers'
										name='repProDealers'
										labelText='Pro Dealers'
										checked={
											repProDealers == "TRUE" ||
											repProDealers == "true" ||
											repProDealers == true ||
											""
										}
										onClick={() =>
											this.onSelectCompanyClick(
												"repProDealers"
											)
										}
										onChange={this.handleInput}
									/>
								</Column>
								<Column xs='12' md='3'>
									<Checkbox
										type='checkbox'
										id='repAllianceLumber'
										name='repAllianceLumber'
										labelText='Alliance Lumber'
										checked={
											repAllianceLumber == "TRUE" ||
											repAllianceLumber == "true" ||
											repAllianceLumber == true ||
											""
										}
										onClick={() =>
											this.onSelectCompanyClick(
												"repAllianceLumber"
											)
										}
										onChange={this.handleInput}
									/>
								</Column>
							</Row>
						</div>
						<Row>
							<Column xs='12' sm='8'>
								{showSuccess ? (
									<p style={{ color: '#a70215' }} className='sf-text--bold sf-mt--lg'>
										{successMessage}
									</p>
								) : null}
								{showErrors ? (
									<p style={{ color: '#a70215' }} className='sf-text--bold sf-mt--lg'>
										{errors.firstName} {errors.lastName}{" "}
										{errors.userRole} {errors.email}{" "}
										{errors.userName} {errors.password}{" "}
										{this.state.removeError}
									</p>
								) : null}
							</Column>
							<Column xs='12' sm='4'>
								<div style={{ float: 'right' }}>
									<Button
										color='gray-80'
										className='sf-mr--sm'
										size='sm'
										outline
										onClick={this.clearEdit}
									>
										Cancel
									</Button>
									{editUserSave ? (
										<Button
											color='black'
											size='sm'
											disabled={!submitActive}
											onClick={this.saveCorpUser}
										>
											Submit
										</Button>
									) : (
										<Button
											color='black'
											size='sm'
											disabled={!submitActive}
											onClick={this.newCorpUser}
										>
											Submit
										</Button>
									)}
								</div>
							</Column>
						</Row>
					</fieldset>
					{loading ? null : (
						<fieldset className='sf-formGroup'>
							<legend className='sf-display--block required'>Current Corporate Users</legend>
							<table className='data_table'>
								<thead className='data_table_headrow '>
									<tr>
										<th scope='col'>
											<span className='sr-only'>
												Remove
											</span>
										</th>
										<th scope='col'>
											<span className='sr-only'>
												Edit
											</span>
										</th>
										<th scope='col'>User Role</th>
										<th scope='col'>First Name</th>
										<th scope='col'>Last Name</th>
										<th scope='col'>User Name</th>
										<th scope='col'>Email Address</th>
										<th scope='col'>Last Login</th>
									</tr>
								</thead>
								<tbody>
									{loading
										? null
										: corpUsers?.map(function(
											corpUser,
											index
										  ) {
											return (
												<tr key={index} className='data_table_row'>
													<td>
														<a className='sf-mr--md' role='button' onClick={() =>
															this.editCorpUser(
																corpUser?.userName
															)
														}
														>
																Edit
														</a>
													</td>
													<td>
														{this.state.isUser && corpUser.userName === this.state.isUser ? 'Remove' : <a className='sf-mr--md' role='button' onClick={() => this.onRemoveUserModalTrigger(corpUser?._id)}> Remove</a>}

													</td>
													<td>
														{corpUser?.userRole}
													</td>
													<td>
														{corpUser?.firstName}
													</td>
													<td>
														{corpUser?.lastName}
													</td>
													<td>
														{corpUser?.userName}
													</td>
													<td>
														{corpUser?.email}
													</td>
													<td>
														{corpUser.lastLogin !== undefined &&	this.formatDate(corpUser.lastLogin)}
													</td>
												</tr>
											);
										  },
										  this)}
								</tbody>
							</table>
						</fieldset>
					)}
				</Container>
				<Modal isOpen={this.state.removeUserModalOpen} onClose={this.editUserCloseClick}>
					<div>
						<Container>
							<h2>Confirm user removal</h2>
						</Container>
					</div>
					<div className='sf-text--right'>
						<Button color='primary' className='sf-mr--md' onClick={() => this.removeUser(this.state.removeUserUserId)}>Remove</Button>
						<Button color='black' onClick={this.removeUserCloseClick} >Cancel</Button>
					</div>
				</Modal>
			</Layout>
		);
	}
}

// Admin.propTypes = {
// 	data: PropTypes.object
// };

export default UserAdmin;
