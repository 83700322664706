import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, List } from "sash-foundation";

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";
import Nav from "../../../components/common/nav";
import "./index.scss";

const GetAQuote = ({ data }) => {
	const page = data.contentstackLinkOnlyPage;

	return (
		<Layout>
			<SEO title={page.browser_title} />

			{/* <Nav /> */}

			<Container width={1200} className='sf-pb--2xl'>
				<h1 style={{ fontSize: '2rem', color: '#676767' }} className='sf-text--uppercase sf-text--semibold'>{page.heading}</h1>

				<div className='radius-box'>
					<p className='radius-box-title'>{page.heading}</p>

					<List
						bullets='none'
					>
						{page.standard_list.link.map((links, index) => (
							<li key={index} className='sf-my--sm'>
								<a
									href={links.link.href}
									data-gtm-linktype={links.gtm_link_type}
									data-gtm-linktext={links.gtm_link_text}
									target={links.target}
									rel={links.target === '_blank' ? 'noopener noreferrer' : ''}
									title={links.ada_title}
								>
									{links.link.title}
								</a>
							</li>
						))}
					</List>
				</div>
			</Container>
		</Layout>
	);
};

GetAQuote.propTypes = {
	data: PropTypes.object
};

export default GetAQuote;

export const query = graphql`
{
	contentstackLinkOnlyPage(id: { eq: "ab89a7e0-1b38-5a71-9d7b-ef0becea31be" }) {
		browser_title

		heading

		standard_list {
		  	group_heading
		  	link {
				...link
		  	}
		}
	}
}
`;
